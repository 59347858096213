

// --------------------------------------------------------------------------
//   VARIABLE SETTINGS
// --------------------------------------------------------------------------


// Namespace for themeing & testing
// Apply: .#{$namespace}textarea
// --------------------------------------------------------------------------

$namespace             : '';


// Colours
// --------------------------------------------------------------------------

// These are to be replaced
$color-grey            : #a19a8f;
$color-grey-rule       : rgba($color-grey,0.4);
$color-grey-lite       : rgba($color-grey,0.25);

// Do not use these values directly in your Sass files. Use the more
// specifically named variables below that use these root values.

$base-black                    : #000;
$base-white                    : #FFF;

$palette: (
	black                      : $base-black,
	dark                       : tint($base-black, 15%),
	ash                        : tint($base-black, 22%),
	charcoal                   : tint($base-black, 39%),
	medium                     : tint($base-black, 50%),
	muted                      : tint($base-black, 65%),
	panel                      : tint($base-black, 78%),
	base                       : tint($base-black, 88%),
	light                      : tint($base-black, 94%),
	faint                      : tint($base-black, 97%),
	white                      : $base-white,
	background                 : #fdfdfd,
	neutral                    : #f0eeeb,
	neutral-light              : lighten(#f0eeeb, 4%),
	brand                      : #ffe93b,
	brand-complement           : #887E73,
	warning                    : #FFD40C,
	bad-news                   : #C60C0C,
	good-news                  : #97C70A
);

$root-color: (
	grey-85                    : tint($base-black, 15%),
	grey-78                    : tint($base-black, 22%),
	grey-66                    : tint($base-black, 34%),
	grey-50                    : tint($base-black, 50%),
	grey-40                    : tint($base-black, 60%),
	grey-35                    : tint($base-black, 65%),
	grey-25                    : tint($base-black, 75%),
	grey-12                    : tint($base-black, 88%),
	grey-06                    : tint($base-black, 94%),
	grey-03                    : tint($base-black, 97%),
	background                 : #fdfdfd,
	neutral                    : #f0eeeb,
	neutral-light              : lighten(#f0eeeb, 4%),
	brand                      : #ffe93b,
	brand-faint                : lighten(#0071bc, 56%),
	brand-light                : lighten(#0071bc, 59%),
	brand-muted                : #E5F0F8,
	brand-dark                 : darken(#0071bc, 15%),
	brand-alt                  : #f15a24,
	brand-alt-dark             : darken(#f15a24, 10%),
	brand-alt-muted            : desaturate(#f15a24, 20%),
	brand-alt-muted-dark       : darken(#f15a24, 20%),
	warning                    : #FFD40C,
	bad-news                   : #C60C0C,
	good-news                  : #97C70A
);

// Base Colors for all UI elements

$color: (

	ui: (
		white                    : $base-white,
		faint                    : map-fetch($root-color, grey-03),
		light                    : map-fetch($root-color, grey-06),
		base                     : map-fetch($root-color, grey-12),
		muted                    : map-fetch($root-color, grey-25),
		medium                   : map-fetch($root-color, grey-40),
		charcoal                 : map-fetch($root-color, grey-78),
		dark                     : map-fetch($root-color, grey-85),
		background               : map-fetch($root-color, background),
		neutral                  : map-fetch($root-color, neutral),
		neutral-light            : map-fetch($root-color, neutral-light),
		brand                    : map-fetch($root-color, brand),
		brand-light              : map-fetch($root-color, brand-light),
		brand-faint              : map-fetch($root-color, brand-faint),
		brand-muted              : map-fetch($root-color, brand-muted),
		brand-dark               : map-fetch($root-color, brand-dark),
		brand-alt                : map-fetch($root-color, brand-alt),
		brand-alt-dark           : map-fetch($root-color, brand-alt-dark),
		brand-alt-muted          : map-fetch($root-color, brand-alt-muted),
		brand-alt-muted-dark     : map-fetch($root-color, brand-alt-muted-dark),
		warning                  : map-fetch($root-color, warning)
	),

	link: (
		base                     : map-fetch($root-color, brand),
		brand-light              : map-fetch($root-color, brand-light),
		bad-news                 : map-fetch($root-color, bad-news),
		white                    : $base-white
	),

	background: (
		base                     : $base-white,
		white                    : $base-white,
		faint                    : map-fetch($root-color, grey-03),
		light                    : map-fetch($root-color, grey-06),
		muted                    : map-fetch($root-color, grey-35),
		medium                   : map-fetch($root-color, grey-50),
		charcoal                 : map-fetch($root-color, grey-78),
		black                    : $base-black,
		brand                    : map-fetch($root-color, brand),
		brand-dark               : map-fetch($root-color, brand-dark),
		brand-light              : #F2F7FC,
		warning                  : #FCF8E3
	),

	text: (
		base                     : map-fetch($root-color, grey-85),
		background               : map-fetch($root-color, background),
		white                    : $base-white,
		faint                    : map-fetch($root-color, grey-12),
		light                    : map-fetch($root-color, grey-25),
		muted                    : map-fetch($root-color, grey-35),
		medium                   : map-fetch($root-color, grey-50),
		charcoal                 : map-fetch($root-color, grey-66),
		dark                     : map-fetch($root-color, grey-85),
		brand                    : map-fetch($root-color, brand),
		brand-dark               : map-fetch($root-color, brand-dark),
		brand-alt                : map-fetch($root-color, brand-alt),
		brand-alt-dark           : map-fetch($root-color, brand-alt-dark),
		warning                  : map-fetch($root-color, warning)
	)

);

// http://www.sitepoint.com/debugging-sass-maps/
// I can't get this to work, but it'd be very handy…
// .Debug { @include debug-map($color); }


// Sizes map
// --------------------------------------------------------------------------

$base-font-size-unit      : 16;
$base-line-height-unit    : 1.5;
$base-font-size           : $base-font-size-unit * 1px;
$base-line-height         : ($base-font-size-unit * $base-line-height-unit) * 1px; //25.5
$space-unit               : rem($base-line-height);
$space                    : $space-unit * 1;

$size: (

	border-radius: (
		base              : rem(4),
		large             : rem(6)
	),

	breakpoint: (
		'max-xs'          : ( max-width:  600px ),
		'max-sm'          : ( max-width:  768px ),
		'ip6'             : ( min-width:  374px ),
		'ip6p'            : ( min-width:  415px ),
		'xs'              : ( min-width:  600px ),
		'sm'              : ( min-width:  769px ),
		'md'              : ( min-width:  992px ),
		'lg'              : ( min-width: 1200px ),
		'wp'              : ( min-width: 1240px ),
		'xl'              : ( min-width: 1700px )
	),

	button-height: (
		base              : rem(40)
	),

	container: (
		modal-form        : rem(740),
		modal-form-sm     : 80%,
		default           : rem(1040),
		half              : rem(520),
		text-column       : rem(1040),
		picture           : rem(960),
		wide-picture      : rem(1240)
	),

	font: (
		textXs: (
			xs            : rem(11),
			base          : rem(12)
		),
		textSm: (
			xs            : rem(12),
			base          : rem(14)
		),
		textRg: (
			xs            : rem($base-font-size-unit * 0.8825),
			base          : rem($base-font-size-unit)
		),
		textMd: (
			xs            : rem(15),
			base          : rem(18)
		),
		textLg: (
			xs            : rem(17),
			base          : rem(20)
		),
		headerSm: (
			xs            : rem(17),
			base          : rem(20)
		),
		headerRg: (
			xs            : rem(21),
			base          : rem(28)
		),
		headerMd: (
			xs            : rem(21),
			base          : rem(28)
		),
		headerLg: (
			xs            : rem(21),
			base          : rem(28)
		),
		headerXl: (
			xs            : rem(26),
			base          : rem(48)
		),
		close: (
			xs            : rem(33),
			base          : rem(66)
		),
		super: (
			xs            : rem(50),
			base          : rem(100)
		),
	),

	list-inset: (
		margin            : space(1.5),
		padding           : 0
	),
);


// Fonts
// --------------------------------------------------------------------------

// LEGACY: easy google-fonts implementation
// $sans: 'Source Sans Pro';
// $font: join($sans, $font-sans-stack);

// $sans-font-name       : 'BentonSans';
$sans-font-name       : '';
$fonts-path           : '/build/fonts/';
$font-sans-stack      : 'HelveticaNeue', 'Helvetica Neue', Helvetica, Arial, sans-serif;
// $font-sans-stack      : -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Cantarell","Open Sans","Helvetica Neue","Ubuntu",sans-serif;
$font-serif-stack     : Georgia, Times, serif;

// Create font-file variables

$sans-thin            : #{$sans-font-name}thin;
$sans-light           : #{$sans-font-name}light;
$sans-book            : #{$sans-font-name}regular;
$sans-regular         : #{$sans-font-name}regular;
$sans-medium          : #{$sans-font-name}medium;
$sans-bold            : #{$sans-font-name}bold;
$sans-xbold           : #{$sans-font-name}extrabold;
$sans-black           : #{$sans-font-name}black;

$sans-regular-italic  : #{$sans-font-name}regular-italic;
$sans-medium-italic   : #{$sans-font-name}medium-italic;

// Make all font files available
// Mixin options: $font-family, $file-path, $weight, $style, $formats
// Ps. This below prints an unwanted space between the path var and the font var:
// @include font-face($sans-thin, join($fonts-path,$sans-thin) );

@include font-face($sans-thin,    '#{$fonts-path}#{$sans-thin}' );
@include font-face($sans-light,   '#{$fonts-path}#{$sans-light}' );
@include font-face($sans-book,    '#{$fonts-path}#{$sans-book}' );
@include font-face($sans-regular, '#{$fonts-path}#{$sans-regular}' );
@include font-face($sans-medium,  '#{$fonts-path}#{$sans-medium}' );
@include font-face($sans-bold,    '#{$fonts-path}#{$sans-bold}' );
@include font-face($sans-xbold,   '#{$fonts-path}#{$sans-xbold}' );
@include font-face($sans-black,   '#{$fonts-path}#{$sans-black}' );

@include font-face($sans-regular-italic, '#{$fonts-path}#{$sans-regular-italic}' );
@include font-face($sans-medium-italic, '#{$fonts-path}#{$sans-medium-italic}' );

// Apply font-file variables to a font-stack
// Only use these variables in our classes.

$font-sans-thin       : join($sans-thin, $font-sans-stack);
$font-sans-light      : join($sans-light, $font-sans-stack);
$font-sans-book       : join($sans-book, $font-sans-stack);
$font-sans-regular    : join($sans-regular, $font-sans-stack);
$font-sans-regular-i  : join($sans-regular-italic, $font-sans-stack);
$font-sans-medium     : join($sans-medium, $font-sans-stack);
$font-sans-medium-i   : join($sans-medium-italic, $font-sans-stack);
$font-sans-bold       : join($sans-bold, $font-sans-stack);
$font-sans-xbold      : join($sans-xbold, $font-sans-stack);
$font-sans-black      : join($sans-black, $font-sans-stack);

// if using @fontface via a cloud service, eg. Google fonts.

$font-weight: (
	'thin'      : 100,
	'light'     : 200,
	'book'      : 400,
	'regular'   : 500,
	'medium'    : 600,
	'bold'      : 700,
	'black'     : 900
);


// Transitions maps
// --------------------------------------------------------------------------

$transition: (
	duration: (
		fast         : 50ms,
		short        : 150ms,
		long         : 500ms
	),
	type: (
		cubic-static : cubic-bezier(0, -0.1, 1, -0.1),
		cubic-state  : cubic-bezier(0, 1.1, 1, 1.1),
		base         : ease-out,
		fine         : ease-in-out
	)
);


// Z-indexes map
// --------------------------------------------------------------------------

$z-index: (
	'modal'     : 8001,
	'overlay'   : 8000,
	'dropdown'  : 7000,
	'second'    : 2000,
	'first'     : 1000,
	'default'   : 1,
	'below'     : -1,
);
