

// --------------------------------------------------------------------------
// HELPERS
// --------------------------------------------------------------------------


// Full height or width
// --------------------------------------------------------------------------

.u-fullHeight       { height: 100%; }
.u-fullWidth        { width: 100%; }
.u-mxs-fullWidth    { @include respond-to(max-xs) { width: 100%; } }


// Custom flexEmbeds
// --------------------------------------------------------------------------

.FlexEmbed-ratio--letterbox {
	padding-bottom: 56.25%;
	@include respond-to(xs) { padding-bottom: 45%; }
	@include respond-to(md) { padding-bottom: 35%; }
	@include respond-to(xl) { padding-bottom: 25%; }
}


// Colors
// Alt: .u-colorBrand { color: color($increment: brand) !important; }
// --------------------------------------------------------------------------

.u-colorWhite       { color: palette(background) !important; }
.u-colorFaint       { color: palette(faint) !important; }
.u-colorLight       { color: palette(light) !important; }
.u-colorFaint       { color: palette(faint) !important; }
.u-colorMuted       { color: palette(muted) !important; }
.u-colorBase        { color: palette(base) !important; }
.u-colorPanel       { color: palette(panel) !important; }
.u-colorMedium      { color: palette(medium) !important; }
.u-colorDark        { color: palette(charcoal) !important; }
.u-colorBlack       { color: palette(black) !important; }
.u-colorBrand       { color: palette(brand) !important; }
.u-colorBrandAlt    { color: palette(brand-complement) !important; }

.u-colorTextLight   { color: palette(light) !important; }
.u-colorTextMuted   { color: palette(muted) !important; }
.u-colorTextMedium  { color: palette(medium) !important; }

.u-colorMutedBlock {
	* {
		color: palette(muted) !important;
	}

	a {
		@include on-event() {
			color: palette(black);
		}
	}
}

.u-colorBlackBlock {
	* {
		color: palette(black) !important;
	}

	// a {
	//     @include on-event() {
	//         color: palette(black);
	//     }
	// }
}


// Background colors
// --------------------------------------------------------------------------

.u-bgWhite          { background-color: palette(background) !important; }
.u-bgFaint          { background-color: palette(faint); }
.u-bgNeutral        { background-color: palette(neutral) !important; }
.u-bgNeutralLight   { background-color: palette(neutral-light) !important; }
.u-bgCharcoal       { background-color: palette(charcoal) !important; }
.u-bgDark           { background-color: palette(dark) !important; }
.u-bgBlack          { background-color: palette(black) !important; }

.u-bgBrand          { background-color: palette(brand) !important; }
.u-bgBrandLight     { background-color: rgba( palette(brand), 0.4 ) !important; }
.u-bgBrandAlt       { background-color: palette(brand-complement) !important; }

.u-sm-bgNeutral     { @include respond-to(sm) { background-color: palette(neutral) !important; } }


// Font sizes
// --------------------------------------------------------------------------

.u-textSizeXs       { font-size: size(font, text-xs) !important; }
.u-textSizeSm       { font-size: size(font, text-sm) !important; }
.u-textSizeRg       { font-size: size(font, text) !important; }
.u-textSizeLg       { font-size: size(font, text-lg) !important; }

.u-sm-textSizeRg    { @include respond-to(sm) { font-size: size(font, text) !important; } }

.u-headerSizeRg {
	font-size: map-fetch($size, font, headerRg, xs) !important;
	@include respond-to(xs) { font-size: map-fetch($size, font, headerRg, base) !important }
}

.u-textCenterBlock { > * { @extend .u-textCenter; } }
.u-textRightBlock  { > * { @extend .u-textRight; } }

.u-textXsBlock     { > *, a { @include TextXs(); } }
.u-textSmBlock     { > *, a { @include TextSm(); } }


// Font weights
// --------------------------------------------------------------------------

.u-weightRegular    { font-family: $font-sans-regular !important; }
.u-weightMedium     { font-family: $font-sans-medium !important; }
.u-weightBold       { font-family: $font-sans-bold !important; }
.u-weightBlack      { font-family: $font-sans-black !important; }

.u-sm-weightBold    { @include respond-to(sm) { font-family: $font-sans-bold !important; } }
.u-sm-weightBlack   { @include respond-to(sm) { font-family: $font-sans-black !important; } }


// Border colors
// --------------------------------------------------------------------------

.u-borderBase       { border: 1px solid color(ui, base) !important; }
.u-borderMuted      { border: 1px solid color(ui, muted) !important; }
.u-borderDark       { border: 1px solid color(ui, dark) !important; }
.u-borderBrand      { border: 1px solid color(ui, brand) !important; }
.u-borderBrandLight { border: 1px solid color(ui, brand-light) !important; }

.u-sm-borderDark    { @include respond-to(sm) { border: 1px solid color(ui, dark) !important; } }


// Rounded wells. Usually require a background-color or a border.
// --------------------------------------------------------------------------

.u-well             { border-radius: size(border-radius, base); }
.u-well--lg         { border-radius: size(border-radius, large); }


// Opacity
// --------------------------------------------------------------------------

.u-opaque           { opacity: 1 !important; }


// Align blocks
// --------------------------------------------------------------------------

.u-alignBlockUp {
	@include respond-to(sm) {
		// transform: translateY( rem(-5) );
		margin-top: rem(-5);
	}
}

.u-alignBlockDown {
	@include respond-to(sm) {
		margin-top: rem(5);
		// transform: translateY( rem(8) );
	}
}
