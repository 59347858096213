

// --------------------------------------------------------------------------
// BOOTSTRAP SCROLLSPY COMPONENT
//  @URL: http://getbootstrap.com/javascript/#scrollspy
// --------------------------------------------------------------------------


body {
    position: relative;
}


// .nav .active {
//   font-weight: bold;
//   background: #72bcd4;
// }
//
// .nav .nav {
//   display: none;
// }
//
// .nav .active .nav {
//   display: block;
// }
//
// .nav .nav a {
//   font-weight: normal;
//   font-size: .85em;
// }
//
// .nav .nav span {
//   margin: 0 5px 0 2px;
// }
//
// .nav .nav .active a,
// .nav .nav .active:hover a,
// .nav .nav .active:focus a {
//   font-weight: bold;
//   padding-left: 30px;
//   border-left: 5px solid black;
// }
//
// .nav .nav .active span,
// .nav .nav .active:hover span,
// .nav .nav .active:focus span {
//   display: none;
// }


// Bootstrap scrollspy module is dependent upon having the '.nav' class. Naughty!
// add the data-target attribute with the ID or class of the parent element of any Bootstrap .nav component.

// .nav {
//
//     // scrollspy styling
//     .active {
//         > a {
//             border-left: 2px solid currentColor;
//             color: color(text, brand);
//             padding-left: rem(11);
//         }
//
//         // for a nav within the nav
//         > ul {
//             display: block;
//         }
//     }
// }
