

// --------------------------------------------------------------------------------------------------
// FLEXMEDIA!
// @url: https://github.com/philipwalton/solved-by-flexbox/blob/master/assets/css/components/media.css
// --------------------------------------------------------------------------------------------------


.Media {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1em;
}

.Media-figure {
    margin-right: 1em;
}

.Media-body {
    flex: 1;
}
.Media-body,
.Media-body :last-child {
    margin-bottom: 0;
}

.Media-title {
    margin: 0 0 .5em;
}

.Media--center {
    align-items: center;
}

.Media--reverse > .Media-figure {
    order: 1;
    margin: 0 0 0 1em;
}
