

// --------------------------------------------------------------------------
// BASE: PAGE
// --------------------------------------------------------------------------


/**
 * Page-level styling from InuitCSS.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project,
 *    sourced from our default variables. The `font-size` is calculated to exist
 *    in rems, the `line-height` is calculated to exist unitlessly.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally. Also req'd for [6].
 * 3. Ensure the page always fills at least the entire height of the viewport.
 * 4. Prevent iOS and IE text size adjust after device orientation change,
 *    without disabling user zoom. (Already in Suit).
 * 5. Fonts on OSX will look more consistent with other systems that do not
 *    render text using sub-pixel anti-aliasing.
 * 6. Fluid scroll for Safari mobile. Requires [2] "overflow-y: scroll".
 *    See: http://css-tricks.com/snippets/css/momentum-scrolling-on-ios-overflow-elements/
 *    [Currently seems to not work though? Needs research].
 * 7. From here down, a typography reset site-wide.
 */

html {
    background-color: color(ui, background);
    // color: color(text, charcoal);
    height: 100%; /* 3 */
    // font-size: 80%; /* 1 */
    // line-height: $base-line-height-unit; /* 1 */
    min-height: 100%; /* 3 */
    overflow-y: scroll; /* 2 */
    // text-size-adjust: 100%; /* 4 */
    // -moz-osx-font-smoothing: grayscale; /* 5 */
    // -webkit-font-smoothing: antialiased; /* 5 */
    -webkit-overflow-scrolling: touch; /* 6 */
}

body {
    // height: 100%;
    //overflow-y: scroll;
    // @include respond-to(md) { padding: 0; }
}
