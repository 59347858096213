

// --------------------------------------------------------------------------
// OBJECT: SVG ICONS
// Ripped from OUI icons
// --------------------------------------------------------------------------


.#{$namespace}Icon {
	width: 16px;
	height: 16px;
	display: inline-block;
	fill: currentColor; // The color of the SVG will inherit the parent's `color`.

	&--sm {
		width: 12px;
		height: 12px;
	}

	&--md {
		width: rem(18);
		height: rem(18);
	}

	&--lg {
		width: rem(32);
		height: rem(32);
	}

	&--xl {
		width: rem(40);
		height: rem(40);
	}

	&--super {
		width: rem(56);
		height: rem(56);
	}

	&--left {
		margin-right: 3px;

		@include respond-to(xs) {
		    margin-right: rem(5);
		}
	}

	&--leftSm {
		margin-right: 1px;

		@include respond-to(xs) {
		    margin-right: rem(2);
		}
	}

	&--right {
		margin-left: 4px;

		@include respond-to(xs) {
		    margin-left: rem(8);
		}
	}

	&--rightSm {
		margin-left: 1px;

		@include respond-to(xs) {
		    margin-left: rem(2);
		}
	}

	// This is a vertical alignment fix when used to the right of `.#{$namespace}icon`.
	+ [class^="#{$namespace}-arrow-inline"] {
		vertical-align: super;
	}

	&--textAlign {
		vertical-align: sub;
		// transform: translateY(-1px);
	}

	&--textLgAlign {
		transform: translateY(1px);
	}

	&--buttonAlign {
		line-height: inherit;
		vertical-align: middle;
	}

	&--formAlign {
		position: relative;
		vertical-align: top;
	}

	&--mediaAlign {
		transform: translateY(rem(-4));
	}
}

/// Icon alignment fixes
/// @description Fix for icons inside buttons with text and icons inside tabs.

// .#{$namespace}button .#{$namespace}Icon {
//   vertical-align: sub;
// }
//
// .#{$namespace}tabs-nav__item .#{$namespace}icon {
//   vertical-align: sub;
// }
