/**
 * Suit consists of:
 *
 * base (A thin layer on top of normalize.css)
 *
 * utils-align: Vertical alignment
 * utils-display: Display types
 * utils-layout: Clearfix, floats, and new block formatting contexts
 * utils-link: Link things
 * utils-offset: The before and after packages
 * utils-position: Positioning utilities
 * utils-size: Percentage sizing utilities
 * utils-text: Text truncation, breaking, and alignment
 * utils-flex: Align elements with Flexbox
 *
 * components-arrange
 * components-button
 * components-flex-embed
 * components-grid
 *
 */

/**
 * A thin layer on top of normalize.css that provides a starting point more
 * suitable for web applications.
 */

/**
 * Prevent margin and border from affecting element width.
 * https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 * `input[type="search"]` overrides normalize.css more specific rule.
 */

html {
  box-sizing: border-box;
}

*,
*::before,
*::after,
input[type="search"] {
  box-sizing: inherit;
}

/**
 * Removes the default spacing and border for appropriate elements.
 */

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
figure,
p,
pre {
  margin: 0;
}

button {
  background: transparent;
  border: 0;
  padding: 0;
}

/**
 * Work around a Firefox/IE bug where the transparent `button` background
 * results in a loss of the default `button` focus styles.
 */

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

iframe {
  border: 0;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

/**
 * Suppress the focus outline on elements that cannot be accessed via keyboard.
 * This prevents an unwanted focus outline from appearing around elements that
 * might still respond to pointer events.
 */

[tabindex="-1"]:focus {
  outline: none !important;
}

/**
 * Vertical alignment utilities
 * Depends on an appropriate `display` value.
 */

.u-alignBaseline {
  vertical-align: baseline !important;
}

.u-alignBottom {
  vertical-align: bottom !important;
}

.u-alignMiddle {
  vertical-align: middle !important;
}

.u-alignTop {
  vertical-align: top !important;
}

/**
 * Display-type utilities
 */

.u-block {
  display: block !important;
}

.u-hidden {
  display: none !important;
}

/**
 * Completely remove from the flow but leave available to screen readers.
 */

.u-hiddenVisually {
  position: absolute !important;
  overflow: hidden !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
}

.u-inline {
  display: inline !important;
}

/**
 * 1. Fix for Firefox bug: an image styled `max-width:100%` within an
 * inline-block will display at its default size, and not limit its width to
 * 100% of an ancestral container.
 */

.u-inlineBlock {
  display: inline-block !important;
  max-width: 100%;
  /* 1 */
}

.u-table {
  display: table !important;
}

.u-tableCell {
  display: table-cell !important;
}

.u-tableRow {
  display: table-row !important;
}

/**
 * Contain floats
 *
 * Make an element expand to contain floated children.
 * Uses pseudo-elements (micro clearfix).
 *
 * 1. The space content is one way to avoid an Opera bug when the
 *    `contenteditable` attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of the
 *    element.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */

.u-cf:before,
.u-cf:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}

.u-cf:after {
  clear: both;
}

/**
 * New block formatting context
 *
 * This affords some useful properties to the element. It won't wrap under
 * floats. Will also contain any floated children.

 * N.B. This will clip overflow. Use the alternative method below if this is
 * problematic.
 */

.u-nbfc {
  overflow: hidden !important;
}

/**
 * New block formatting context (alternative)
 *
 * Alternative method when overflow must not be clipped.
 *
 * 1. Create a new block formatting context (NBFC).
 * 2. Avoid shrink-wrap behaviour of table-cell.
 *
 * N.B. This breaks down in some browsers when elements within this element
 * exceed its width.
 */

.u-nbfcAlt {
  display: table-cell !important;
  /* 1 */
  width: 10000px !important;
  /* 2 */
}

/**
 * Floats
 */

.u-floatLeft {
  float: left !important;
}

.u-floatRight {
  float: right !important;
}

/**
 * Clean link
 *
 * A link without any text-decoration at all.
 */

.u-linkClean,
.u-linkClean:hover,
.u-linkClean:focus,
.u-linkClean:active {
  text-decoration: none !important;
}

/**
 * Link complex
 *
 * A common pattern is to have a link with several pieces of text and/or an
 * icon, where only one piece of text should display the underline when the
 * link is the subject of user interaction.
 *
 * Example HTML:
 *
 * <a class="u-linkComplex" href="#">
 *   Link complex
 *   <span class="u-linkComplexTarget">target</span>
 * </a>
 */

.u-linkComplex,
.u-linkComplex:hover,
.u-linkComplex:focus,
.u-linkComplex:active {
  text-decoration: none !important;
}

.u-linkComplex:hover .u-linkComplexTarget,
.u-linkComplex:focus .u-linkComplexTarget,
.u-linkComplex:active .u-linkComplexTarget {
  text-decoration: underline !important;
}

/**
 * Block-level link
 *
 * Combination of traits commonly used in vertical navigation lists.
 */

.u-linkBlock,
.u-linkBlock:hover,
.u-linkBlock:focus,
.u-linkBlock:active {
  display: block !important;
  text-decoration: none !important;
}

/**
 * Specify the proportional offset after an element.
 * Intentional redundancy build into each set of unit classes.
 * Supports: 2, 4, 5, 6, 8, 10, 12 section
 */

.u-after1of12 {
  margin-right: 8.333333333333332% !important;
}

.u-after1of10 {
  margin-right: 10% !important;
}

.u-after1of8 {
  margin-right: 12.5% !important;
}

.u-after1of6,
.u-after2of12 {
  margin-right: 16.666666666666664% !important;
}

.u-after1of5,
.u-after2of10 {
  margin-right: 20% !important;
}

.u-after1of4,
.u-after2of8,
.u-after3of12 {
  margin-right: 25% !important;
}

.u-after3of10 {
  margin-right: 30% !important;
}

.u-after1of3,
.u-after2of6,
.u-after4of12 {
  margin-right: 33.33333333333333% !important;
}

.u-after3of8 {
  margin-right: 37.5% !important;
}

.u-after2of5,
.u-after4of10 {
  margin-right: 40% !important;
}

.u-after5of12 {
  margin-right: 41.66666666666667% !important;
}

.u-after1of2,
.u-after2of4,
.u-after3of6,
.u-after4of8,
.u-after5of10,
.u-after6of12 {
  margin-right: 50% !important;
}

.u-after7of12 {
  margin-right: 58.333333333333336% !important;
}

.u-after3of5,
.u-after6of10 {
  margin-right: 60% !important;
}

.u-after5of8 {
  margin-right: 62.5% !important;
}

.u-after2of3,
.u-after4of6,
.u-after8of12 {
  margin-right: 66.66666666666666% !important;
}

.u-after7of10 {
  margin-right: 70% !important;
}

.u-after3of4,
.u-after6of8,
.u-after9of12 {
  margin-right: 75% !important;
}

.u-after4of5,
.u-after8of10 {
  margin-right: 80% !important;
}

.u-after5of6,
.u-after10of12 {
  margin-right: 83.33333333333334% !important;
}

.u-after7of8 {
  margin-right: 87.5% !important;
}

.u-after9of10 {
  margin-right: 90% !important;
}

.u-after11of12 {
  margin-right: 91.66666666666666% !important;
}

/**
 * Offset: breakpoint 1 (extra-small)
 *
 * Specify the proportional offset after an element.
 * Intentional redundancy build into each set of unit classes.
 * Supports: 2, 4, 5, 6, 8, 10, 12 section
 */

@include respond-to(xs) {
  .u-xs-afterNone {
    margin-left: 0 !important;
  }

  .u-xs-after1of12 {
    margin-right: 8.333333333333332% !important;
  }

  .u-xs-after1of10 {
    margin-right: 10% !important;
  }

  .u-xs-after1of8 {
    margin-right: 12.5% !important;
  }

  .u-xs-after1of6,
  .u-xs-after2of12 {
    margin-right: 16.666666666666664% !important;
  }

  .u-xs-after1of5,
  .u-xs-after2of10 {
    margin-right: 20% !important;
  }

  .u-xs-after1of4,
  .u-xs-after2of8,
  .u-xs-after3of12 {
    margin-right: 25% !important;
  }

  .u-xs-after3of10 {
    margin-right: 30% !important;
  }

  .u-xs-after1of3,
  .u-xs-after2of6,
  .u-xs-after4of12 {
    margin-right: 33.33333333333333% !important;
  }

  .u-xs-after3of8 {
    margin-right: 37.5% !important;
  }

  .u-xs-after2of5,
  .u-xs-after4of10 {
    margin-right: 40% !important;
  }

  .u-xs-after5of12 {
    margin-right: 41.66666666666667% !important;
  }

  .u-xs-after1of2,
  .u-xs-after2of4,
  .u-xs-after3of6,
  .u-xs-after4of8,
  .u-xs-after5of10,
  .u-xs-after6of12 {
    margin-right: 50% !important;
  }

  .u-xs-after7of12 {
    margin-right: 58.333333333333336% !important;
  }

  .u-xs-after3of5,
  .u-xs-after6of10 {
    margin-right: 60% !important;
  }

  .u-xs-after5of8 {
    margin-right: 62.5% !important;
  }

  .u-xs-after2of3,
  .u-xs-after4of6,
  .u-xs-after8of12 {
    margin-right: 66.66666666666666% !important;
  }

  .u-xs-after7of10 {
    margin-right: 70% !important;
  }

  .u-xs-after3of4,
  .u-xs-after6of8,
  .u-xs-after9of12 {
    margin-right: 75% !important;
  }

  .u-xs-after4of5,
  .u-xs-after8of10 {
    margin-right: 80% !important;
  }

  .u-xs-after5of6,
  .u-xs-after10of12 {
    margin-right: 83.33333333333334% !important;
  }

  .u-xs-after7of8 {
    margin-right: 87.5% !important;
  }

  .u-xs-after9of10 {
    margin-right: 90% !important;
  }

  .u-xs-after11of12 {
    margin-right: 91.66666666666666% !important;
  }
}

/**
 * Offset: breakpoint 2 (small)
 *
 * Specify the proportional offset after an element.
 * Intentional redundancy build into each set of unit classes.
 * Supports: 2, 4, 5, 6, 8, 10, 12 section
 */

@include respond-to(sm) {
  .u-sm-afterNone {
    margin-left: 0 !important;
  }

  .u-sm-after1of12 {
    margin-right: 8.333333333333332% !important;
  }

  .u-sm-after1of10 {
    margin-right: 10% !important;
  }

  .u-sm-after1of8 {
    margin-right: 12.5% !important;
  }

  .u-sm-after1of6,
  .u-sm-after2of12 {
    margin-right: 16.666666666666664% !important;
  }

  .u-sm-after1of5,
  .u-sm-after2of10 {
    margin-right: 20% !important;
  }

  .u-sm-after1of4,
  .u-sm-after2of8,
  .u-sm-after3of12 {
    margin-right: 25% !important;
  }

  .u-sm-after3of10 {
    margin-right: 30% !important;
  }

  .u-sm-after1of3,
  .u-sm-after2of6,
  .u-sm-after4of12 {
    margin-right: 33.33333333333333% !important;
  }

  .u-sm-after3of8 {
    margin-right: 37.5% !important;
  }

  .u-sm-after2of5,
  .u-sm-after4of10 {
    margin-right: 40% !important;
  }

  .u-sm-after5of12 {
    margin-right: 41.66666666666667% !important;
  }

  .u-sm-after1of2,
  .u-sm-after2of4,
  .u-sm-after3of6,
  .u-sm-after4of8,
  .u-sm-after5of10,
  .u-sm-after6of12 {
    margin-right: 50% !important;
  }

  .u-sm-after7of12 {
    margin-right: 58.333333333333336% !important;
  }

  .u-sm-after3of5,
  .u-sm-after6of10 {
    margin-right: 60% !important;
  }

  .u-sm-after5of8 {
    margin-right: 62.5% !important;
  }

  .u-sm-after2of3,
  .u-sm-after4of6,
  .u-sm-after8of12 {
    margin-right: 66.66666666666666% !important;
  }

  .u-sm-after7of10 {
    margin-right: 70% !important;
  }

  .u-sm-after3of4,
  .u-sm-after6of8,
  .u-sm-after9of12 {
    margin-right: 75% !important;
  }

  .u-sm-after4of5,
  .u-sm-after8of10 {
    margin-right: 80% !important;
  }

  .u-sm-after5of6,
  .u-sm-after10of12 {
    margin-right: 83.33333333333334% !important;
  }

  .u-sm-after7of8 {
    margin-right: 87.5% !important;
  }

  .u-sm-after9of10 {
    margin-right: 90% !important;
  }

  .u-sm-after11of12 {
    margin-right: 91.66666666666666% !important;
  }
}

/**
 * Offset: breakpoint 3 (medium)
 *
 * Specify the proportional offset after an element.
 * Intentional redundancy build into each set of unit classes.
 * Supports: 2, 4, 5, 6, 8, 10, 12 section
 */

@include respond-to(md) {
  .u-md-afterNone {
    margin-left: 0 !important;
  }

  .u-md-after1of12 {
    margin-right: 8.333333333333332% !important;
  }

  .u-md-after1of10 {
    margin-right: 10% !important;
  }

  .u-md-after1of8 {
    margin-right: 12.5% !important;
  }

  .u-md-after1of6,
  .u-md-after2of12 {
    margin-right: 16.666666666666664% !important;
  }

  .u-md-after1of5,
  .u-md-after2of10 {
    margin-right: 20% !important;
  }

  .u-md-after1of4,
  .u-md-after2of8,
  .u-md-after3of12 {
    margin-right: 25% !important;
  }

  .u-md-after3of10 {
    margin-right: 30% !important;
  }

  .u-md-after1of3,
  .u-md-after2of6,
  .u-md-after4of12 {
    margin-right: 33.33333333333333% !important;
  }

  .u-md-after3of8 {
    margin-right: 37.5% !important;
  }

  .u-md-after2of5,
  .u-md-after4of10 {
    margin-right: 40% !important;
  }

  .u-md-after5of12 {
    margin-right: 41.66666666666667% !important;
  }

  .u-md-after1of2,
  .u-md-after2of4,
  .u-md-after3of6,
  .u-md-after4of8,
  .u-md-after5of10,
  .u-md-after6of12 {
    margin-right: 50% !important;
  }

  .u-md-after7of12 {
    margin-right: 58.333333333333336% !important;
  }

  .u-md-after3of5,
  .u-md-after6of10 {
    margin-right: 60% !important;
  }

  .u-md-after5of8 {
    margin-right: 62.5% !important;
  }

  .u-md-after2of3,
  .u-md-after4of6,
  .u-md-after8of12 {
    margin-right: 66.66666666666666% !important;
  }

  .u-md-after7of10 {
    margin-right: 70% !important;
  }

  .u-md-after3of4,
  .u-md-after6of8,
  .u-md-after9of12 {
    margin-right: 75% !important;
  }

  .u-md-after4of5,
  .u-md-after8of10 {
    margin-right: 80% !important;
  }

  .u-md-after5of6,
  .u-md-after10of12 {
    margin-right: 83.33333333333334% !important;
  }

  .u-md-after7of8 {
    margin-right: 87.5% !important;
  }

  .u-md-after9of10 {
    margin-right: 90% !important;
  }

  .u-md-after11of12 {
    margin-right: 91.66666666666666% !important;
  }
}

/**
 * Offset: breakpoint 3 (large)
 *
 * Specify the proportional offset after an element.
 * Intentional redundancy build into each set of unit classes.
 * Supports: 2, 4, 5, 6, 8, 10, 12 section
 */

@include respond-to(lg) {
  .u-lg-afterNone {
    margin-left: 0 !important;
  }

  .u-lg-after1of12 {
    margin-right: 8.333333333333332% !important;
  }

  .u-lg-after1of10 {
    margin-right: 10% !important;
  }

  .u-lg-after1of8 {
    margin-right: 12.5% !important;
  }

  .u-lg-after1of6,
  .u-lg-after2of12 {
    margin-right: 16.666666666666664% !important;
  }

  .u-lg-after1of5,
  .u-lg-after2of10 {
    margin-right: 20% !important;
  }

  .u-lg-after1of4,
  .u-lg-after2of8,
  .u-lg-after3of12 {
    margin-right: 25% !important;
  }

  .u-lg-after3of10 {
    margin-right: 30% !important;
  }

  .u-lg-after1of3,
  .u-lg-after2of6,
  .u-lg-after4of12 {
    margin-right: 33.33333333333333% !important;
  }

  .u-lg-after3of8 {
    margin-right: 37.5% !important;
  }

  .u-lg-after2of5,
  .u-lg-after4of10 {
    margin-right: 40% !important;
  }

  .u-lg-after5of12 {
    margin-right: 41.66666666666667% !important;
  }

  .u-lg-after1of2,
  .u-lg-after2of4,
  .u-lg-after3of6,
  .u-lg-after4of8,
  .u-lg-after5of10,
  .u-lg-after6of12 {
    margin-right: 50% !important;
  }

  .u-lg-after7of12 {
    margin-right: 58.333333333333336% !important;
  }

  .u-lg-after3of5,
  .u-lg-after6of10 {
    margin-right: 60% !important;
  }

  .u-lg-after5of8 {
    margin-right: 62.5% !important;
  }

  .u-lg-after2of3,
  .u-lg-after4of6,
  .u-lg-after8of12 {
    margin-right: 66.66666666666666% !important;
  }

  .u-lg-after7of10 {
    margin-right: 70% !important;
  }

  .u-lg-after3of4,
  .u-lg-after6of8,
  .u-lg-after9of12 {
    margin-right: 75% !important;
  }

  .u-lg-after4of5,
  .u-lg-after8of10 {
    margin-right: 80% !important;
  }

  .u-lg-after5of6,
  .u-lg-after10of12 {
    margin-right: 83.33333333333334% !important;
  }

  .u-lg-after7of8 {
    margin-right: 87.5% !important;
  }

  .u-lg-after9of10 {
    margin-right: 90% !important;
  }

  .u-lg-after11of12 {
    margin-right: 91.66666666666666% !important;
  }
}

/**
 * Specify the proportional offset before an object.
 * Intentional redundancy build into each set of unit classes.
 * Supports: 2, 3, 4, 5, 6, 8, 10, 12 section
 */

.u-before1of12 {
  margin-left: 8.333333333333332% !important;
}

.u-before1of10 {
  margin-left: 10% !important;
}

.u-before1of8 {
  margin-left: 12.5% !important;
}

.u-before1of6,
.u-before2of12 {
  margin-left: 16.666666666666664% !important;
}

.u-before1of5,
.u-before2of10 {
  margin-left: 20% !important;
}

.u-before1of4,
.u-before2of8,
.u-before3of12 {
  margin-left: 25% !important;
}

.u-before3of10 {
  margin-left: 30% !important;
}

.u-before1of3,
.u-before2of6,
.u-before4of12 {
  margin-left: 33.33333333333333% !important;
}

.u-before3of8 {
  margin-left: 37.5% !important;
}

.u-before2of5,
.u-before4of10 {
  margin-left: 40% !important;
}

.u-before5of12 {
  margin-left: 41.66666666666667% !important;
}

.u-before1of2,
.u-before2of4,
.u-before3of6,
.u-before4of8,
.u-before5of10,
.u-before6of12 {
  margin-left: 50% !important;
}

.u-before7of12 {
  margin-left: 58.333333333333336% !important;
}

.u-before3of5,
.u-before6of10 {
  margin-left: 60% !important;
}

.u-before5of8 {
  margin-left: 62.5% !important;
}

.u-before2of3,
.u-before4of6,
.u-before8of12 {
  margin-left: 66.66666666666666% !important;
}

.u-before7of10 {
  margin-left: 70% !important;
}

.u-before3of4,
.u-before6of8,
.u-before9of12 {
  margin-left: 75% !important;
}

.u-before4of5,
.u-before8of10 {
  margin-left: 80% !important;
}

.u-before5of6,
.u-before10of12 {
  margin-left: 83.33333333333334% !important;
}

.u-before7of8 {
  margin-left: 87.5% !important;
}

.u-before9of10 {
  margin-left: 90% !important;
}

.u-before11of12 {
  margin-left: 91.66666666666666% !important;
}

/**
 * Offset: breakpoint 1 (extra-small)
 *
 * Specify the proportional offset before an element.
 * Intentional redundancy build into each set of unit classes.
 * Supports: 2, 3, 4, 5, 6, 8, 10, 12 section
 */

@include respond-to(xs) {
  .u-xs-beforeNone {
    margin-left: 0 !important;
  }

  .u-xs-before1of12 {
    margin-left: 8.333333333333332% !important;
  }

  .u-xs-before1of10 {
    margin-left: 10% !important;
  }

  .u-xs-before1of8 {
    margin-left: 12.5% !important;
  }

  .u-xs-before1of6,
  .u-xs-before2of12 {
    margin-left: 16.666666666666664% !important;
  }

  .u-xs-before1of5,
  .u-xs-before2of10 {
    margin-left: 20% !important;
  }

  .u-xs-before1of4,
  .u-xs-before2of8,
  .u-xs-before3of12 {
    margin-left: 25% !important;
  }

  .u-xs-before3of10 {
    margin-left: 30% !important;
  }

  .u-xs-before1of3,
  .u-xs-before2of6,
  .u-xs-before4of12 {
    margin-left: 33.33333333333333% !important;
  }

  .u-xs-before3of8 {
    margin-left: 37.5% !important;
  }

  .u-xs-before2of5,
  .u-xs-before4of10 {
    margin-left: 40% !important;
  }

  .u-xs-before5of12 {
    margin-left: 41.66666666666667% !important;
  }

  .u-xs-before1of2,
  .u-xs-before2of4,
  .u-xs-before3of6,
  .u-xs-before4of8,
  .u-xs-before5of10,
  .u-xs-before6of12 {
    margin-left: 50% !important;
  }

  .u-xs-before7of12 {
    margin-left: 58.333333333333336% !important;
  }

  .u-xs-before3of5,
  .u-xs-before6of10 {
    margin-left: 60% !important;
  }

  .u-xs-before5of8 {
    margin-left: 62.5% !important;
  }

  .u-xs-before2of3,
  .u-xs-before4of6,
  .u-xs-before8of12 {
    margin-left: 66.66666666666666% !important;
  }

  .u-xs-before7of10 {
    margin-left: 70% !important;
  }

  .u-xs-before3of4,
  .u-xs-before6of8,
  .u-xs-before9of12 {
    margin-left: 75% !important;
  }

  .u-xs-before4of5,
  .u-xs-before8of10 {
    margin-left: 80% !important;
  }

  .u-xs-before5of6,
  .u-xs-before10of12 {
    margin-left: 83.33333333333334% !important;
  }

  .u-xs-before7of8 {
    margin-left: 87.5% !important;
  }

  .u-xs-before9of10 {
    margin-left: 90% !important;
  }

  .u-xs-before11of12 {
    margin-left: 91.66666666666666% !important;
  }
}

/**
 * Offset: breakpoint 2 (small)
 *
 * Specify the proportional offset before an element.
 * Intentional redundancy build into each set of unit classes.
 * Supports: 2, 3, 4, 5, 6, 8, 10, 12 section
 */

@include respond-to(sm) {
  .u-sm-beforeNone {
    margin-left: 0 !important;
  }

  .u-sm-before1of12 {
    margin-left: 8.333333333333332% !important;
  }

  .u-sm-before1of10 {
    margin-left: 10% !important;
  }

  .u-sm-before1of8 {
    margin-left: 12.5% !important;
  }

  .u-sm-before1of6,
  .u-sm-before2of12 {
    margin-left: 16.666666666666664% !important;
  }

  .u-sm-before1of5,
  .u-sm-before2of10 {
    margin-left: 20% !important;
  }

  .u-sm-before1of4,
  .u-sm-before2of8,
  .u-sm-before3of12 {
    margin-left: 25% !important;
  }

  .u-sm-before3of10 {
    margin-left: 30% !important;
  }

  .u-sm-before1of3,
  .u-sm-before2of6,
  .u-sm-before4of12 {
    margin-left: 33.33333333333333% !important;
  }

  .u-sm-before3of8 {
    margin-left: 37.5% !important;
  }

  .u-sm-before2of5,
  .u-sm-before4of10 {
    margin-left: 40% !important;
  }

  .u-sm-before5of12 {
    margin-left: 41.66666666666667% !important;
  }

  .u-sm-before1of2,
  .u-sm-before2of4,
  .u-sm-before3of6,
  .u-sm-before4of8,
  .u-sm-before5of10,
  .u-sm-before6of12 {
    margin-left: 50% !important;
  }

  .u-sm-before7of12 {
    margin-left: 58.333333333333336% !important;
  }

  .u-sm-before3of5,
  .u-sm-before6of10 {
    margin-left: 60% !important;
  }

  .u-sm-before5of8 {
    margin-left: 62.5% !important;
  }

  .u-sm-before2of3,
  .u-sm-before4of6,
  .u-sm-before8of12 {
    margin-left: 66.66666666666666% !important;
  }

  .u-sm-before7of10 {
    margin-left: 70% !important;
  }

  .u-sm-before3of4,
  .u-sm-before6of8,
  .u-sm-before9of12 {
    margin-left: 75% !important;
  }

  .u-sm-before4of5,
  .u-sm-before8of10 {
    margin-left: 80% !important;
  }

  .u-sm-before5of6,
  .u-sm-before10of12 {
    margin-left: 83.33333333333334% !important;
  }

  .u-sm-before7of8 {
    margin-left: 87.5% !important;
  }

  .u-sm-before9of10 {
    margin-left: 90% !important;
  }

  .u-sm-before11of12 {
    margin-left: 91.66666666666666% !important;
  }
}

/**
 * Offset: breakpoint 3 (medium)
 *
 * Specify the proportional offset before an element.
 * Intentional redundancy build into each set of unit classes.
 * Supports: 2, 3, 4, 5, 6, 8, 10, 12 section
 */

@include respond-to(md) {
  .u-md-beforeNone {
    margin-left: 0 !important;
  }

  .u-md-before1of12 {
    margin-left: 8.333333333333332% !important;
  }

  .u-md-before1of10 {
    margin-left: 10% !important;
  }

  .u-md-before1of8 {
    margin-left: 12.5% !important;
  }

  .u-md-before1of6,
  .u-md-before2of12 {
    margin-left: 16.666666666666664% !important;
  }

  .u-md-before1of5,
  .u-md-before2of10 {
    margin-left: 20% !important;
  }

  .u-md-before1of4,
  .u-md-before2of8,
  .u-md-before3of12 {
    margin-left: 25% !important;
  }

  .u-md-before3of10 {
    margin-left: 30% !important;
  }

  .u-md-before1of3,
  .u-md-before2of6,
  .u-md-before4of12 {
    margin-left: 33.33333333333333% !important;
  }

  .u-md-before3of8 {
    margin-left: 37.5% !important;
  }

  .u-md-before2of5,
  .u-md-before4of10 {
    margin-left: 40% !important;
  }

  .u-md-before5of12 {
    margin-left: 41.66666666666667% !important;
  }

  .u-md-before1of2,
  .u-md-before2of4,
  .u-md-before3of6,
  .u-md-before4of8,
  .u-md-before5of10,
  .u-md-before6of12 {
    margin-left: 50% !important;
  }

  .u-md-before7of12 {
    margin-left: 58.333333333333336% !important;
  }

  .u-md-before3of5,
  .u-md-before6of10 {
    margin-left: 60% !important;
  }

  .u-md-before5of8 {
    margin-left: 62.5% !important;
  }

  .u-md-before2of3,
  .u-md-before4of6,
  .u-md-before8of12 {
    margin-left: 66.66666666666666% !important;
  }

  .u-md-before7of10 {
    margin-left: 70% !important;
  }

  .u-md-before3of4,
  .u-md-before6of8,
  .u-md-before9of12 {
    margin-left: 75% !important;
  }

  .u-md-before4of5,
  .u-md-before8of10 {
    margin-left: 80% !important;
  }

  .u-md-before5of6,
  .u-md-before10of12 {
    margin-left: 83.33333333333334% !important;
  }

  .u-md-before7of8 {
    margin-left: 87.5% !important;
  }

  .u-md-before9of10 {
    margin-left: 90% !important;
  }

  .u-md-before11of12 {
    margin-left: 91.66666666666666% !important;
  }
}

/**
 * Offset: breakpoint 3 (large)
 *
 * Specify the proportional offset before an element.
 * Intentional redundancy build into each set of unit classes.
 * Supports: 2, 3, 4, 5, 6, 8, 10, 12 section
 */

@include respond-to(lg) {
  .u-lg-beforeNone {
    margin-left: 0 !important;
  }

  .u-lg-before1of12 {
    margin-left: 8.333333333333332% !important;
  }

  .u-lg-before1of10 {
    margin-left: 10% !important;
  }

  .u-lg-before1of8 {
    margin-left: 12.5% !important;
  }

  .u-lg-before1of6,
  .u-lg-before2of12 {
    margin-left: 16.666666666666664% !important;
  }

  .u-lg-before1of5,
  .u-lg-before2of10 {
    margin-left: 20% !important;
  }

  .u-lg-before1of4,
  .u-lg-before2of8,
  .u-lg-before3of12 {
    margin-left: 25% !important;
  }

  .u-lg-before3of10 {
    margin-left: 30% !important;
  }

  .u-lg-before1of3,
  .u-lg-before2of6,
  .u-lg-before4of12 {
    margin-left: 33.33333333333333% !important;
  }

  .u-lg-before3of8 {
    margin-left: 37.5% !important;
  }

  .u-lg-before2of5,
  .u-lg-before4of10 {
    margin-left: 40% !important;
  }

  .u-lg-before5of12 {
    margin-left: 41.66666666666667% !important;
  }

  .u-lg-before1of2,
  .u-lg-before2of4,
  .u-lg-before3of6,
  .u-lg-before4of8,
  .u-lg-before5of10,
  .u-lg-before6of12 {
    margin-left: 50% !important;
  }

  .u-lg-before7of12 {
    margin-left: 58.333333333333336% !important;
  }

  .u-lg-before3of5,
  .u-lg-before6of10 {
    margin-left: 60% !important;
  }

  .u-lg-before5of8 {
    margin-left: 62.5% !important;
  }

  .u-lg-before2of3,
  .u-lg-before4of6,
  .u-lg-before8of12 {
    margin-left: 66.66666666666666% !important;
  }

  .u-lg-before7of10 {
    margin-left: 70% !important;
  }

  .u-lg-before3of4,
  .u-lg-before6of8,
  .u-lg-before9of12 {
    margin-left: 75% !important;
  }

  .u-lg-before4of5,
  .u-lg-before8of10 {
    margin-left: 80% !important;
  }

  .u-lg-before5of6,
  .u-lg-before10of12 {
    margin-left: 83.33333333333334% !important;
  }

  .u-lg-before7of8 {
    margin-left: 87.5% !important;
  }

  .u-lg-before9of10 {
    margin-left: 90% !important;
  }

  .u-lg-before11of12 {
    margin-left: 91.66666666666666% !important;
  }
}

.u-posAbsolute {
  position: absolute !important;
}

/**
 * Pins to all corners by default. But when a width and/or height are
 * provided, the element will be centered in its nearest relatively-positioned
 * ancestor.
 */

.u-posAbsoluteCenter {
  bottom: 0 !important;
  left: 0 !important;
  margin: auto !important;
  position: absolute !important;
  right: 0 !important;
  top: 0 !important;
}

/**
 * 1. Make sure fixed elements are promoted into a new layer, for performance
 *    reasons.
 */

.u-posFixed {
  position: fixed !important;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  /* 1 */
}

.u-posRelative {
  position: relative !important;
}

.u-posStatic {
  position: static !important;
}

/**
 * @define utilities
 * Sizing utilities
 */

/* Proportional widths
   ========================================================================== */

/**
 * Specify the proportional width of an object.
 * Intentional redundancy build into each set of unit classes.
 * Supports: 2, 3, 4, 5, 6, 8, 10, 12 part
 *
 * 1. Use `flex-basis: auto` with a width to avoid box-sizing bug in IE10/11
 *    http://git.io/vllMD
 */

/* postcss-bem-linter: ignore */

[class*="u-size"] {
  -webkit-flex-basis: auto !important;
  -ms-flex-preferred-size: auto !important;
  flex-basis: auto !important;
  /* 1 */
}

.u-size1of12 {
  width: 8.333333333333332% !important;
}

.u-size1of10 {
  width: 10% !important;
}

.u-size1of8 {
  width: 12.5% !important;
}

.u-size1of6,
.u-size2of12 {
  width: 16.666666666666664% !important;
}

.u-size1of5,
.u-size2of10 {
  width: 20% !important;
}

.u-size1of4,
.u-size2of8,
.u-size3of12 {
  width: 25% !important;
}

.u-size3of10 {
  width: 30% !important;
}

.u-size1of3,
.u-size2of6,
.u-size4of12 {
  width: 33.33333333333333% !important;
}

.u-size3of8 {
  width: 37.5% !important;
}

.u-size2of5,
.u-size4of10 {
  width: 40% !important;
}

.u-size5of12 {
  width: 41.66666666666667% !important;
}

.u-size1of2,
.u-size2of4,
.u-size3of6,
.u-size4of8,
.u-size5of10,
.u-size6of12 {
  width: 50% !important;
}

.u-size7of12 {
  width: 58.333333333333336% !important;
}

.u-size3of5,
.u-size6of10 {
  width: 60% !important;
}

.u-size5of8 {
  width: 62.5% !important;
}

.u-size2of3,
.u-size4of6,
.u-size8of12 {
  width: 66.66666666666666% !important;
}

.u-size7of10 {
  width: 70% !important;
}

.u-size3of4,
.u-size6of8,
.u-size9of12 {
  width: 75% !important;
}

.u-size4of5,
.u-size8of10 {
  width: 80% !important;
}

.u-size5of6,
.u-size10of12 {
  width: 83.33333333333334% !important;
}

.u-size7of8 {
  width: 87.5% !important;
}

.u-size9of10 {
  width: 90% !important;
}

.u-size11of12 {
  width: 91.66666666666666% !important;
}

/* Intrinsic widths
   ========================================================================== */

/**
 * Make an element fill the remaining space.
 *
 * 1. Be explicit to work around IE10 bug with shorthand flex - http://git.io/vllC7
 * 2. IE10 ignores previous `flex-basis` value. Setting again here fixes - http://git.io/vllMt
 */

.u-sizeFill {
  -webkit-flex: 1 1 0% !important;
  -ms-flex: 1 1 0% !important;
  flex: 1 1 0% !important;
  /* 1 */
  -webkit-flex-basis: 0% !important;
  -ms-flex-preferred-size: 0% !important;
  flex-basis: 0% !important;
  /* 2 */
}

/**
 * An alternative method to make an element fill the remaining space.
 * Distributes space based on the initial width and height of the element
 *
 * http://www.w3.org/TR/css-flexbox/images/rel-vs-abs-flex.svg
 */

.u-sizeFillAlt {
  -webkit-flex: 1 1 auto !important;
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
  -webkit-flex-basis: auto !important;
  -ms-flex-preferred-size: auto !important;
  flex-basis: auto !important;
}

/**
 * Make an element the width of its parent.
 */

.u-sizeFull {
  box-sizing: border-box !important;
  display: block !important;
  width: 100% !important;
}

/**
 * @define utilities
 * Size: breakpoint iphone6 (extra-extra-small)
 */

@include respond-to(ip6) {
  /* Proportional widths: breakpoint 1 (extra-small)
     ========================================================================== */

  /* postcss-bem-linter: ignore */

  [class*="u-ip6-size"] {
    -webkit-flex-basis: auto !important;
    -ms-flex-preferred-size: auto !important;
    flex-basis: auto !important;
    /* 1 */
  }

  .u-ip6-size5of12 {
    width: 41.66666666666667% !important;
  }

  .u-ip6-size7of12 {
    width: 58.333333333333336% !important;
  }

}


/**
 * @define utilities
 * Size: breakpoint 1 (extra-small)
 */

@include respond-to(xs) {
  /* Proportional widths: breakpoint 1 (extra-small)
     ========================================================================== */

  /**
   * Specify the proportional width of an object.
   * Intentional redundancy build into each set of unit classes.
   * Supports: 2, 3, 4, 5, 6, 8, 10, 12 part
   *
   * 1. Use `flex-basis: auto` with a width to avoid box-sizing bug in IE10/11
   *    http://git.io/vllMD
   */

  /* postcss-bem-linter: ignore */

  [class*="u-xs-size"] {
    -webkit-flex-basis: auto !important;
    -ms-flex-preferred-size: auto !important;
    flex-basis: auto !important;
    /* 1 */
  }

  .u-xs-size1of12 {
    width: 8.333333333333332% !important;
  }

  .u-xs-size1of10 {
    width: 10% !important;
  }

  .u-xs-size1of8 {
    width: 12.5% !important;
  }

  .u-xs-size1of6,
  .u-xs-size2of12 {
    width: 16.666666666666664% !important;
  }

  .u-xs-size1of5,
  .u-xs-size2of10 {
    width: 20% !important;
  }

  .u-xs-size1of4,
  .u-xs-size2of8,
  .u-xs-size3of12 {
    width: 25% !important;
  }

  .u-xs-size3of10 {
    width: 30% !important;
  }

  .u-xs-size1of3,
  .u-xs-size2of6,
  .u-xs-size4of12 {
    width: 33.33333333333333% !important;
  }

  .u-xs-size3of8 {
    width: 37.5% !important;
  }

  .u-xs-size2of5,
  .u-xs-size4of10 {
    width: 40% !important;
  }

  .u-xs-size5of12 {
    width: 41.66666666666667% !important;
  }

  .u-xs-size1of2,
  .u-xs-size2of4,
  .u-xs-size3of6,
  .u-xs-size4of8,
  .u-xs-size5of10,
  .u-xs-size6of12 {
    width: 50% !important;
  }

  .u-xs-size7of12 {
    width: 58.333333333333336% !important;
  }

  .u-xs-size3of5,
  .u-xs-size6of10 {
    width: 60% !important;
  }

  .u-xs-size5of8 {
    width: 62.5% !important;
  }

  .u-xs-size2of3,
  .u-xs-size4of6,
  .u-xs-size8of12 {
    width: 66.66666666666666% !important;
  }

  .u-xs-size7of10 {
    width: 70% !important;
  }

  .u-xs-size3of4,
  .u-xs-size6of8,
  .u-xs-size9of12 {
    width: 75% !important;
  }

  .u-xs-size4of5,
  .u-xs-size8of10 {
    width: 80% !important;
  }

  .u-xs-size5of6,
  .u-xs-size10of12 {
    width: 83.33333333333334% !important;
  }

  .u-xs-size7of8 {
    width: 87.5% !important;
  }

  .u-xs-size9of10 {
    width: 90% !important;
  }

  .u-xs-size11of12 {
    width: 91.66666666666666% !important;
  }

  /* Intrinsic widths
     ========================================================================== */

  /**
   * Make an element fill the remaining space.
   *
   * 1. Be explicit to work around IE10 bug with shorthand flex - http://git.io/vllC7
   * 2. IE10 ignores previous `flex-basis` value. Setting again here fixes - http://git.io/vllMt
   */

  .u-xs-sizeFill {
    -webkit-flex: 1 1 0% !important;
    -ms-flex: 1 1 0% !important;
    flex: 1 1 0% !important;
    /* 1 */
    -webkit-flex-basis: 0% !important;
    -ms-flex-preferred-size: 0% !important;
    flex-basis: 0% !important;
    /* 2 */
  }

  /**
   * An alternative method to make an element fill the remaining space.
   * Distributes space based on the initial width and height of the element
   *
   * http://www.w3.org/TR/css-flexbox/images/rel-vs-abs-flex.svg
   */

  .u-xs-sizeFillAlt {
    -webkit-flex: 1 1 auto !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
    -webkit-flex-basis: auto !important;
    -ms-flex-preferred-size: auto !important;
    flex-basis: auto !important;
  }

  /**
   * Make an element the width of its parent.
   */

  .u-xs-sizeFull {
    box-sizing: border-box !important;
    display: block !important;
    width: 100% !important;
  }
}

@include respond-to(sm) {
  /* Proportional widths: breakpoint 2 (small)
     ========================================================================== */

  /**
   * Specify the proportional width of an object.
   * Intentional redundancy build into each set of unit classes.
   * Supports: 2, 3, 4, 5, 6, 8, 10, 12 part
   *
   * 1. Use `flex-basis: auto` with a width to avoid box-sizing bug in IE10/11
   *    http://git.io/vllMD
   */

  /* postcss-bem-linter: ignore */

  [class*="u-sm-size"] {
    -webkit-flex-basis: auto !important;
    -ms-flex-preferred-size: auto !important;
    flex-basis: auto !important;
    /* 1 */
  }

  .u-sm-size1of12 {
    width: 8.333333333333332% !important;
  }

  .u-sm-size1of10 {
    width: 10% !important;
  }

  .u-sm-size1of8 {
    width: 12.5% !important;
  }

  .u-sm-size1of6,
  .u-sm-size2of12 {
    width: 16.666666666666664% !important;
  }

  .u-sm-size1of5,
  .u-sm-size2of10 {
    width: 20% !important;
  }

  .u-sm-size1of4,
  .u-sm-size2of8,
  .u-sm-size3of12 {
    width: 25% !important;
  }

  .u-sm-size3of10 {
    width: 30% !important;
  }

  .u-sm-size1of3,
  .u-sm-size2of6,
  .u-sm-size4of12 {
    width: 33.33333333333333% !important;
  }

  .u-sm-size3of8 {
    width: 37.5% !important;
  }

  .u-sm-size2of5,
  .u-sm-size4of10 {
    width: 40% !important;
  }

  .u-sm-size5of12 {
    width: 41.66666666666667% !important;
  }

  .u-sm-size1of2,
  .u-sm-size2of4,
  .u-sm-size3of6,
  .u-sm-size4of8,
  .u-sm-size5of10,
  .u-sm-size6of12 {
    width: 50% !important;
  }

  .u-sm-size7of12 {
    width: 58.333333333333336% !important;
  }

  .u-sm-size3of5,
  .u-sm-size6of10 {
    width: 60% !important;
  }

  .u-sm-size5of8 {
    width: 62.5% !important;
  }

  .u-sm-size2of3,
  .u-sm-size4of6,
  .u-sm-size8of12 {
    width: 66.66666666666666% !important;
  }

  .u-sm-size7of10 {
    width: 70% !important;
  }

  .u-sm-size3of4,
  .u-sm-size6of8,
  .u-sm-size9of12 {
    width: 75% !important;
  }

  .u-sm-size4of5,
  .u-sm-size8of10 {
    width: 80% !important;
  }

  .u-sm-size5of6,
  .u-sm-size10of12 {
    width: 83.33333333333334% !important;
  }

  .u-sm-size7of8 {
    width: 87.5% !important;
  }

  .u-sm-size9of10 {
    width: 90% !important;
  }

  .u-sm-size11of12 {
    width: 91.66666666666666% !important;
  }

  /* Intrinsic widths
     ========================================================================== */

  /**
   * Make an element fill the remaining space.
   *
   * 1. Be explicit to work around IE10 bug with shorthand flex - http://git.io/vllC7
   * 2. IE10 ignores previous `flex-basis` value. Setting again here fixes - http://git.io/vllMt
   */

  .u-sm-sizeFill {
    -webkit-flex: 1 1 0% !important;
    -ms-flex: 1 1 0% !important;
    flex: 1 1 0% !important;
    /* 1 */
    -webkit-flex-basis: 0% !important;
    -ms-flex-preferred-size: 0% !important;
    flex-basis: 0% !important;
    /* 2 */
  }

  /**
   * An alternative method to make an element fill the remaining space.
   * Distributes space based on the initial width and height of the element
   *
   * http://www.w3.org/TR/css-flexbox/images/rel-vs-abs-flex.svg
   */

  .u-sm-sizeFillAlt {
    -webkit-flex: 1 1 auto !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
    -webkit-flex-basis: auto !important;
    -ms-flex-preferred-size: auto !important;
    flex-basis: auto !important;
  }

  /**
   * Make an element the width of its parent.
   */

  .u-sm-sizeFull {
    box-sizing: border-box !important;
    display: block !important;
    width: 100% !important;
  }
}

/**
 * @define utilities
 * Size: breakpoint 3 (medium)
 */

@include respond-to(md) {
  /* Proportional widths: breakpoint 3 (medium)
     ========================================================================== */

  /**
   * Specify the proportional width of an object.
   * Intentional redundancy build into each set of unit classes.
   * Supports: 2, 3, 4, 5, 6, 8, 10, 12 part
   *
   * 1. Use `flex-basis: auto` with a width to avoid box-sizing bug in IE10/11
   *    http://git.io/vllMD
   */

  /* postcss-bem-linter: ignore */

  [class*="u-md-size"] {
    -webkit-flex-basis: auto !important;
    -ms-flex-preferred-size: auto !important;
    flex-basis: auto !important;
    /* 1 */
  }

  .u-md-size1of12 {
    width: 8.333333333333332% !important;
  }

  .u-md-size1of10 {
    width: 10% !important;
  }

  .u-md-size1of8 {
    width: 12.5% !important;
  }

  .u-md-size1of6,
  .u-md-size2of12 {
    width: 16.666666666666664% !important;
  }

  .u-md-size1of5,
  .u-md-size2of10 {
    width: 20% !important;
  }

  .u-md-size1of4,
  .u-md-size2of8,
  .u-md-size3of12 {
    width: 25% !important;
  }

  .u-md-size3of10 {
    width: 30% !important;
  }

  .u-md-size1of3,
  .u-md-size2of6,
  .u-md-size4of12 {
    width: 33.33333333333333% !important;
  }

  .u-md-size3of8 {
    width: 37.5% !important;
  }

  .u-md-size2of5,
  .u-md-size4of10 {
    width: 40% !important;
  }

  .u-md-size5of12 {
    width: 41.66666666666667% !important;
  }

  .u-md-size1of2,
  .u-md-size2of4,
  .u-md-size3of6,
  .u-md-size4of8,
  .u-md-size5of10,
  .u-md-size6of12 {
    width: 50% !important;
  }

  .u-md-size7of12 {
    width: 58.333333333333336% !important;
  }

  .u-md-size3of5,
  .u-md-size6of10 {
    width: 60% !important;
  }

  .u-md-size5of8 {
    width: 62.5% !important;
  }

  .u-md-size2of3,
  .u-md-size4of6,
  .u-md-size8of12 {
    width: 66.66666666666666% !important;
  }

  .u-md-size7of10 {
    width: 70% !important;
  }

  .u-md-size3of4,
  .u-md-size6of8,
  .u-md-size9of12 {
    width: 75% !important;
  }

  .u-md-size4of5,
  .u-md-size8of10 {
    width: 80% !important;
  }

  .u-md-size5of6,
  .u-md-size10of12 {
    width: 83.33333333333334% !important;
  }

  .u-md-size7of8 {
    width: 87.5% !important;
  }

  .u-md-size9of10 {
    width: 90% !important;
  }

  .u-md-size11of12 {
    width: 91.66666666666666% !important;
  }

  /* Intrinsic widths
     ========================================================================== */

  /**
   * Make an element fill the remaining space.
   *
   * 1. Be explicit to work around IE10 bug with shorthand flex - http://git.io/vllC7
   * 2. IE10 ignores previous `flex-basis` value. Setting again here fixes - http://git.io/vllMt
   */

  .u-md-sizeFill {
    -webkit-flex: 1 1 0% !important;
    -ms-flex: 1 1 0% !important;
    flex: 1 1 0% !important;
    /* 1 */
    -webkit-flex-basis: 0% !important;
    -ms-flex-preferred-size: 0% !important;
    flex-basis: 0% !important;
    /* 2 */
  }

  /**
   * An alternative method to make an element fill the remaining space.
   * Distributes space based on the initial width and height of the element
   *
   * http://www.w3.org/TR/css-flexbox/images/rel-vs-abs-flex.svg
   */

  .u-md-sizeFillAlt {
    -webkit-flex: 1 1 auto !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
    -webkit-flex-basis: auto !important;
    -ms-flex-preferred-size: auto !important;
    flex-basis: auto !important;
  }

  /**
   * Make an element the width of its parent.
   */

  .u-md-sizeFull {
    box-sizing: border-box !important;
    display: block !important;
    width: 100% !important;
  }
}

/**
 * @define utilities
 * Size: breakpoint 3 (large)
 */

@include respond-to(lg) {
  /* Proportional widths: breakpoint 3 (large)
     ========================================================================== */

  /**
   * Specify the proportional width of an object.
   * Intentional redundancy build into each set of unit classes.
   * Supports: 2, 3, 4, 5, 6, 8, 10, 12 part
   *
   * 1. Use `flex-basis: auto` with a width to avoid box-sizing bug in IE10/11
   *    http://git.io/vllMD
   */

  /* postcss-bem-linter: ignore */

  [class*="u-lg-size"] {
    -webkit-flex-basis: auto !important;
    -ms-flex-preferred-size: auto !important;
    flex-basis: auto !important;
    /* 1 */
  }

  .u-lg-size1of12 {
    width: 8.333333333333332% !important;
  }

  .u-lg-size1of10 {
    width: 10% !important;
  }

  .u-lg-size1of8 {
    width: 12.5% !important;
  }

  .u-lg-size1of6,
  .u-lg-size2of12 {
    width: 16.666666666666664% !important;
  }

  .u-lg-size1of5,
  .u-lg-size2of10 {
    width: 20% !important;
  }

  .u-lg-size1of4,
  .u-lg-size2of8,
  .u-lg-size3of12 {
    width: 25% !important;
  }

  .u-lg-size3of10 {
    width: 30% !important;
  }

  .u-lg-size1of3,
  .u-lg-size2of6,
  .u-lg-size4of12 {
    width: 33.33333333333333% !important;
  }

  .u-lg-size3of8 {
    width: 37.5% !important;
  }

  .u-lg-size2of5,
  .u-lg-size4of10 {
    width: 40% !important;
  }

  .u-lg-size5of12 {
    width: 41.66666666666667% !important;
  }

  .u-lg-size1of2,
  .u-lg-size2of4,
  .u-lg-size3of6,
  .u-lg-size4of8,
  .u-lg-size5of10,
  .u-lg-size6of12 {
    width: 50% !important;
  }

  .u-lg-size7of12 {
    width: 58.333333333333336% !important;
  }

  .u-lg-size3of5,
  .u-lg-size6of10 {
    width: 60% !important;
  }

  .u-lg-size5of8 {
    width: 62.5% !important;
  }

  .u-lg-size2of3,
  .u-lg-size4of6,
  .u-lg-size8of12 {
    width: 66.66666666666666% !important;
  }

  .u-lg-size7of10 {
    width: 70% !important;
  }

  .u-lg-size3of4,
  .u-lg-size6of8,
  .u-lg-size9of12 {
    width: 75% !important;
  }

  .u-lg-size4of5,
  .u-lg-size8of10 {
    width: 80% !important;
  }

  .u-lg-size5of6,
  .u-lg-size10of12 {
    width: 83.33333333333334% !important;
  }

  .u-lg-size7of8 {
    width: 87.5% !important;
  }

  .u-lg-size9of10 {
    width: 90% !important;
  }

  .u-lg-size11of12 {
    width: 91.66666666666666% !important;
  }

  /* Intrinsic widths
     ========================================================================== */

  /**
   * Make an element fill the remaining space.
   *
   * 1. Be explicit to work around IE10 bug with shorthand flex - http://git.io/vllC7
   * 2. IE10 ignores previous `flex-basis` value. Setting again here fixes - http://git.io/vllMt
   */

  .u-lg-sizeFill {
    -webkit-flex: 1 1 0% !important;
    -ms-flex: 1 1 0% !important;
    flex: 1 1 0% !important;
    /* 1 */
    -webkit-flex-basis: 0% !important;
    -ms-flex-preferred-size: 0% !important;
    flex-basis: 0% !important;
    /* 2 */
  }

  /**
   * An alternative method to make an element fill the remaining space.
   * Distributes space based on the initial width and height of the element
   *
   * http://www.w3.org/TR/css-flexbox/images/rel-vs-abs-flex.svg
   */

  .u-lg-sizeFillAlt {
    -webkit-flex: 1 1 auto !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
    -webkit-flex-basis: auto !important;
    -ms-flex-preferred-size: auto !important;
    flex-basis: auto !important;
  }

  /**
   * Make an element the width of its parent.
   */

  .u-lg-sizeFull {
    box-sizing: border-box !important;
    display: block !important;
    width: 100% !important;
  }
}

/**
 * Word breaking
 *
 * Break strings when their length exceeds the width of their container.
 */

.u-textBreak {
  word-wrap: break-word !important;
}

/**
 * Horizontal text alignment
 */

.u-textCenter {
  text-align: center !important;
}

.u-textLeft {
  text-align: left !important;
}

.u-textRight {
  text-align: right !important;
}

/**
 * Inherit the ancestor's text color.
 */

.u-textInheritColor {
  color: inherit !important;
}

/**
 * Enables font kerning in all browsers.
 * http://blog.typekit.com/2014/02/05/kerning-on-the-web/
 *
 * 1. Chrome (not Windows), Firefox, Safari 6+, iOS, Android
 * 2. Chrome (not Windows), Firefox, IE 10+
 * 3. Safari 7 and future browsers
 */

.u-textKern {
  text-rendering: optimizeLegibility;
  /* 1 */
  -webkit-font-feature-settings: "kern" 1;
  font-feature-settings: "kern" 1;
  /* 2 */
  -webkit-font-kerning: normal;
  font-kerning: normal;
  /* 3 */
}

/**
 * Prevent whitespace wrapping
 */

.u-textNoWrap {
  white-space: nowrap !important;
}

/**
 * Text truncation
 *
 * Prevent text from wrapping onto multiple lines, and truncate with an
 * ellipsis.
 *
 * 1. Ensure that the node has a maximum width after which truncation can
 *    occur.
 * 2. Fix for IE 8/9 if `word-wrap: break-word` is in effect on ancestor
 *    nodes.
 */

.u-textTruncate {
  max-width: 100%;
  /* 1 */
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  word-wrap: normal !important;
  /* 2 */
}

/** @define utilities */

/* Applies to flex container
   ========================================================================== */

/**
 * Container
 */

.u-flex {
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.u-flexInline {
  display: -webkit-inline-flex !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

/**
 * Direction: row
 */

.u-flexRow {
  -webkit-flex-direction: row !important;
  -ms-flex-direction: row !important;
  flex-direction: row !important;
}

.u-flexRowReverse {
  -webkit-flex-direction: row-reverse !important;
  -ms-flex-direction: row-reverse !important;
  flex-direction: row-reverse !important;
}

/**
 * Direction: column
 */

.u-flexCol {
  -webkit-flex-direction: column !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}

.u-flexColReverse {
  -webkit-flex-direction: column-reverse !important;
  -ms-flex-direction: column-reverse !important;
  flex-direction: column-reverse !important;
}

/**
 * Wrap
 */

.u-flexWrap {
  -webkit-flex-wrap: wrap !important;
  -ms-flex-wrap: wrap !important;
  flex-wrap: wrap !important;
}

.u-flexNoWrap {
  -webkit-flex-wrap: nowrap !important;
  -ms-flex-wrap: nowrap !important;
  flex-wrap: nowrap !important;
}

.u-flexWrapReverse {
  -webkit-flex-wrap: wrap-reverse !important;
  -ms-flex-wrap: wrap-reverse !important;
  flex-wrap: wrap-reverse !important;
}

/**
 * Align items along the main axis of the current line of the flex container
 */

.u-flexJustifyStart {
  -webkit-justify-content: flex-start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
}

.u-flexJustifyEnd {
  -webkit-justify-content: flex-end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
}

.u-flexJustifyCenter {
  -webkit-justify-content: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.u-flexJustifyBetween {
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.u-flexJustifyAround {
  -webkit-justify-content: space-around !important;
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important;
}

/**
 * Align items in the cross axis of the current line of the flex container
 * Similar to `justify-content` but in the perpendicular direction
 */

.u-flexAlignItemsStart {
  -webkit-align-items: flex-start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.u-flexAlignItemsEnd {
  -webkit-align-items: flex-end !important;
  -ms-flex-align: end !important;
  align-items: flex-end !important;
}

.u-flexAlignItemsCenter {
  -webkit-align-items: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.u-flexAlignItemsStretch {
  -webkit-align-items: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

.u-flexAlignItemsBaseline {
  -webkit-align-items: baseline !important;
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}

/**
 * Aligns items within the flex container when there is extra space in the cross-axis
 *
 * Has no effect when there is only one line of flex items.
 */

.u-flexAlignContentStart {
  -webkit-align-content: flex-start !important;
  -ms-flex-line-pack: start !important;
  align-content: flex-start !important;
}

.u-flexAlignContentEnd {
  -webkit-align-content: flex-end !important;
  -ms-flex-line-pack: end !important;
  align-content: flex-end !important;
}

.u-flexAlignContentCenter {
  -webkit-align-content: center !important;
  -ms-flex-line-pack: center !important;
  align-content: center !important;
}

.u-flexAlignContentStretch {
  -webkit-align-content: stretch !important;
  -ms-flex-line-pack: stretch !important;
  align-content: stretch !important;
}

.u-flexAlignContentBetween {
  -webkit-align-content: space-between !important;
  -ms-flex-line-pack: justify !important;
  align-content: space-between !important;
}

.u-flexAlignContentAround {
  -webkit-align-content: space-around !important;
  -ms-flex-line-pack: distribute !important;
  align-content: space-around !important;
}

/**
 * 1. Set the flex-shrink default explicitly to fix IE10 - http://git.io/vllC7
 */

/* postcss-bem-linter: ignore */

.u-flex > *,
.u-flexInline > * {
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  /* 1 */
}

/* Applies to flex items
   ========================================================================== */

/**
 * Override default alignment of single item when specified by `align-items`
 */

.u-flexAlignSelfStart {
  -webkit-align-self: flex-start !important;
  -ms-flex-item-align: start !important;
  align-self: flex-start !important;
}

.u-flexAlignSelfEnd {
  -webkit-align-self: flex-end !important;
  -ms-flex-item-align: end !important;
  align-self: flex-end !important;
}

.u-flexAlignSelfCenter {
  -webkit-align-self: center !important;
  -ms-flex-item-align: center !important;
  align-self: center !important;
}

.u-flexAlignSelfStretch {
  -webkit-align-self: stretch !important;
  -ms-flex-item-align: stretch !important;
  align-self: stretch !important;
}

.u-flexAlignSelfBaseline {
  -webkit-align-self: baseline !important;
  -ms-flex-item-align: baseline !important;
  align-self: baseline !important;
}

.u-flexAlignSelfAuto {
  -webkit-align-self: auto !important;
  -ms-flex-item-align: auto !important;
  align-self: auto !important;
}

/**
 * Change order without editing underlying HTML
 */

.u-flexOrderFirst {
  -webkit-order: -1 !important;
  -ms-flex-order: -1 !important;
  order: -1 !important;
}

.u-flexOrderLast {
  -webkit-order: 1 !important;
  -ms-flex-order: 1 !important;
  order: 1 !important;
}

.u-flexOrderNone {
  -webkit-order: 0 !important;
  -ms-flex-order: 0 !important;
  order: 0 !important;
}

/**
 * Specify the flex grow factor, which determines how much the flex item will
 * grow relative to the rest of the flex items in the flex container.
 *
 * Supports 1-5 proportions
 *
 * 1. Provide all values to avoid IE10 bug with shorthand flex - http://git.io/vllC7
 *    Use `0%` to avoid bug in IE10/11 with unitless flex basis - http://git.io/vllWx
 */

.u-flexGrow1 {
  -webkit-flex: 1 1 0% !important;
  -ms-flex: 1 1 0% !important;
  flex: 1 1 0% !important;
  /* 1 */
}

.u-flexGrow2 {
  -webkit-flex: 2 1 0% !important;
  -ms-flex: 2 1 0% !important;
  flex: 2 1 0% !important;
}

.u-flexGrow3 {
  -webkit-flex: 3 1 0% !important;
  -ms-flex: 3 1 0% !important;
  flex: 3 1 0% !important;
}

.u-flexGrow4 {
  -webkit-flex: 4 1 0% !important;
  -ms-flex: 4 1 0% !important;
  flex: 4 1 0% !important;
}

.u-flexGrow5 {
  -webkit-flex: 5 1 0% !important;
  -ms-flex: 5 1 0% !important;
  flex: 5 1 0% !important;
}

/**
 * @define utilities
 * Size: breakpoint 1 (extra-small)
 */

@include respond-to(xs) {
  /* Applies to flex container
     ========================================================================== */

  /**
   * Container
   */

  .u-xs-flex {
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .u-xs-flexInline {
    display: -webkit-inline-flex !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }

  /**
   * Direction: row
   */

  .u-xs-flexRow {
    -webkit-flex-direction: row !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .u-xs-flexRowReverse {
    -webkit-flex-direction: row-reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  /**
   * Direction: column
   */

  .u-xs-flexCol {
    -webkit-flex-direction: column !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .u-xs-flexColReverse {
    -webkit-flex-direction: column-reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  /**
   * Wrap
   */

  .u-xs-flexWrap {
    -webkit-flex-wrap: wrap !important;
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .u-xs-flexNoWrap {
    -webkit-flex-wrap: nowrap !important;
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .u-xs-flexWrapReverse {
    -webkit-flex-wrap: wrap-reverse !important;
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  /**
   * Align items along the main axis of the current line of the flex container
   */

  .u-xs-flexJustifyStart {
    -webkit-justify-content: flex-start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .u-xs-flexJustifyEnd {
    -webkit-justify-content: flex-end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .u-xs-flexJustifyCenter {
    -webkit-justify-content: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .u-xs-flexJustifyBetween {
    -webkit-justify-content: space-between !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .u-xs-flexJustifyAround {
    -webkit-justify-content: space-around !important;
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  /**
   * Align items in the cross axis of the current line of the flex container
   * Similar to `justify-content` but in the perpendicular direction
   */

  .u-xs-flexAlignItemsStart {
    -webkit-align-items: flex-start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .u-xs-flexAlignItemsEnd {
    -webkit-align-items: flex-end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .u-xs-flexAlignItemsCenter {
    -webkit-align-items: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .u-xs-flexAlignItemsStretch {
    -webkit-align-items: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .u-xs-flexAlignItemsBaseline {
    -webkit-align-items: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  /**
   * Aligns items within the flex container when there is extra space in the cross-axis
   *
   * Has no effect when there is only one line of flex items.
   */

  .u-xs-flexAlignContentStart {
    -webkit-align-content: flex-start !important;
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .u-xs-flexAlignContentEnd {
    -webkit-align-content: flex-end !important;
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .u-xs-flexAlignContentCenter {
    -webkit-align-content: center !important;
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .u-xs-flexAlignContentStretch {
    -webkit-align-content: stretch !important;
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .u-xs-flexAlignContentBetween {
    -webkit-align-content: space-between !important;
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .u-xs-flexAlignContentAround {
    -webkit-align-content: space-around !important;
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  /**
   * 1. Set the flex-shrink default explicitly to fix IE10 - http://git.io/vllC7
   */

  /* postcss-bem-linter: ignore */

  .u-xs-flex > *,
  .u-xs-flexInline > * {
    -webkit-flex-shrink: 1;
    -ms-flex-negative: 1;
    flex-shrink: 1;
    /* 1 */
  }

  /* Applies to flex items
     ========================================================================== */

  /**
   * Override default alignment of single item when specified by `align-items`
   */

  .u-xs-flexAlignSelfStart {
    -webkit-align-self: flex-start !important;
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .u-xs-flexAlignSelfEnd {
    -webkit-align-self: flex-end !important;
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .u-xs-flexAlignSelfCenter {
    -webkit-align-self: center !important;
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .u-xs-flexAlignSelfStretch {
    -webkit-align-self: stretch !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }

  .u-xs-flexAlignSelfBaseline {
    -webkit-align-self: baseline !important;
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .u-xs-flexAlignSelfAuto {
    -webkit-align-self: auto !important;
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  /**
   * Change order without editing underlying HTML
   */

  .u-xs-flexOrderFirst {
    -webkit-order: -1 !important;
    -ms-flex-order: -1 !important;
    order: -1 !important;
  }

  .u-xs-flexOrderLast {
    -webkit-order: 1 !important;
    -ms-flex-order: 1 !important;
    order: 1 !important;
  }

  .u-xs-flexOrderNone {
    -webkit-order: 0 !important;
    -ms-flex-order: 0 !important;
    order: 0 !important;
  }

  /**
   * Specify the flex grow factor, which determines how much the flex item will
   * grow relative to the rest of the flex items in the flex container.
   *
   * Supports 1-5 proportions
   *
   * 1. Provide all values to avoid IE10 bug with shorthand flex - http://git.io/vllC7
   *    Use `0%` to avoid bug in IE10/11 with unitless flex basis - http://git.io/vllWx
   */

  .u-xs-flexGrow1 {
    -webkit-flex: 1 1 0% !important;
    -ms-flex: 1 1 0% !important;
    flex: 1 1 0% !important;
    /* 1 */
  }

  .u-xs-flexGrow2 {
    -webkit-flex: 2 1 0% !important;
    -ms-flex: 2 1 0% !important;
    flex: 2 1 0% !important;
  }

  .u-xs-flexGrow3 {
    -webkit-flex: 3 1 0% !important;
    -ms-flex: 3 1 0% !important;
    flex: 3 1 0% !important;
  }

  .u-xs-flexGrow4 {
    -webkit-flex: 4 1 0% !important;
    -ms-flex: 4 1 0% !important;
    flex: 4 1 0% !important;
  }

  .u-xs-flexGrow5 {
    -webkit-flex: 5 1 0% !important;
    -ms-flex: 5 1 0% !important;
    flex: 5 1 0% !important;
  }
}

/**
 * @define utilities
 * Size: breakpoint 1 (medium)
 */

@include respond-to(md) {
  /* Applies to flex container
     ========================================================================== */

  /**
   * Container
   */

  .u-md-flex {
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .u-md-flexInline {
    display: -webkit-inline-flex !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }

  /**
   * Direction: row
   */

  .u-md-flexRow {
    -webkit-flex-direction: row !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .u-md-flexRowReverse {
    -webkit-flex-direction: row-reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  /**
   * Direction: column
   */

  .u-md-flexCol {
    -webkit-flex-direction: column !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .u-md-flexColReverse {
    -webkit-flex-direction: column-reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  /**
   * Wrap
   */

  .u-md-flexWrap {
    -webkit-flex-wrap: wrap !important;
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .u-md-flexNoWrap {
    -webkit-flex-wrap: nowrap !important;
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .u-md-flexWrapReverse {
    -webkit-flex-wrap: wrap-reverse !important;
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  /**
   * Align items along the main axis of the current line of the flex container
   */

  .u-md-flexJustifyStart {
    -webkit-justify-content: flex-start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .u-md-flexJustifyEnd {
    -webkit-justify-content: flex-end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .u-md-flexJustifyCenter {
    -webkit-justify-content: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .u-md-flexJustifyBetween {
    -webkit-justify-content: space-between !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .u-md-flexJustifyAround {
    -webkit-justify-content: space-around !important;
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  /**
   * Align items in the cross axis of the current line of the flex container
   * Similar to `justify-content` but in the perpendicular direction
   */

  .u-md-flexAlignItemsStart {
    -webkit-align-items: flex-start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .u-md-flexAlignItemsEnd {
    -webkit-align-items: flex-end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .u-md-flexAlignItemsCenter {
    -webkit-align-items: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .u-md-flexAlignItemsStretch {
    -webkit-align-items: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .u-md-flexAlignItemsBaseline {
    -webkit-align-items: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  /**
   * Aligns items within the flex container when there is extra space in the cross-axis
   *
   * Has no effect when there is only one line of flex items.
   */

  .u-md-flexAlignContentStart {
    -webkit-align-content: flex-start !important;
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .u-md-flexAlignContentEnd {
    -webkit-align-content: flex-end !important;
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .u-md-flexAlignContentCenter {
    -webkit-align-content: center !important;
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .u-md-flexAlignContentStretch {
    -webkit-align-content: stretch !important;
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .u-md-flexAlignContentBetween {
    -webkit-align-content: space-between !important;
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .u-md-flexAlignContentAround {
    -webkit-align-content: space-around !important;
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  /**
   * 1. Set the flex-shrink default explicitly to fix IE10 - http://git.io/vllC7
   */

  /* postcss-bem-linter: ignore */

  .u-md-flex > *,
  .u-md-flexInline > * {
    -webkit-flex-shrink: 1;
    -ms-flex-negative: 1;
    flex-shrink: 1;
    /* 1 */
  }

  /* Applies to flex items
     ========================================================================== */

  /**
   * Override default alignment of single item when specified by `align-items`
   */

  .u-md-flexAlignSelfStart {
    -webkit-align-self: flex-start !important;
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .u-md-flexAlignSelfEnd {
    -webkit-align-self: flex-end !important;
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .u-md-flexAlignSelfCenter {
    -webkit-align-self: center !important;
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .u-md-flexAlignSelfStretch {
    -webkit-align-self: stretch !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }

  .u-md-flexAlignSelfBaseline {
    -webkit-align-self: baseline !important;
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .u-md-flexAlignSelfAuto {
    -webkit-align-self: auto !important;
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  /**
   * Change order without editing underlying HTML
   */

  .u-md-flexOrderFirst {
    -webkit-order: -1 !important;
    -ms-flex-order: -1 !important;
    order: -1 !important;
  }

  .u-md-flexOrderLast {
    -webkit-order: 1 !important;
    -ms-flex-order: 1 !important;
    order: 1 !important;
  }

  .u-md-flexOrderNone {
    -webkit-order: 0 !important;
    -ms-flex-order: 0 !important;
    order: 0 !important;
  }

  /**
   * Specify the flex grow factor, which determines how much the flex item will
   * grow relative to the rest of the flex items in the flex container.
   *
   * Supports 1-5 proportions
   *
   * 1. Provide all values to avoid IE10 bug with shorthand flex - http://git.io/vllC7
   *    Use `0%` to avoid bug in IE10/11 with unitless flex basis - http://git.io/vllWx
   */

  .u-md-flexGrow1 {
    -webkit-flex: 1 1 0% !important;
    -ms-flex: 1 1 0% !important;
    flex: 1 1 0% !important;
    /* 1 */
  }

  .u-md-flexGrow2 {
    -webkit-flex: 2 1 0% !important;
    -ms-flex: 2 1 0% !important;
    flex: 2 1 0% !important;
  }

  .u-md-flexGrow3 {
    -webkit-flex: 3 1 0% !important;
    -ms-flex: 3 1 0% !important;
    flex: 3 1 0% !important;
  }

  .u-md-flexGrow4 {
    -webkit-flex: 4 1 0% !important;
    -ms-flex: 4 1 0% !important;
    flex: 4 1 0% !important;
  }

  .u-md-flexGrow5 {
    -webkit-flex: 5 1 0% !important;
    -ms-flex: 5 1 0% !important;
    flex: 5 1 0% !important;
  }
}

/**
 * @define utilities
 * Size: breakpoint 1 (large)
 */

@include respond-to(lg) {
  /* Applies to flex container
     ========================================================================== */

  /**
   * Container
   */

  .u-lg-flex {
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .u-lg-flexInline {
    display: -webkit-inline-flex !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }

  /**
   * Direction: row
   */

  .u-lg-flexRow {
    -webkit-flex-direction: row !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }

  .u-lg-flexRowReverse {
    -webkit-flex-direction: row-reverse !important;
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }

  /**
   * Direction: column
   */

  .u-lg-flexCol {
    -webkit-flex-direction: column !important;
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }

  .u-lg-flexColReverse {
    -webkit-flex-direction: column-reverse !important;
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }

  /**
   * Wrap
   */

  .u-lg-flexWrap {
    -webkit-flex-wrap: wrap !important;
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }

  .u-lg-flexNoWrap {
    -webkit-flex-wrap: nowrap !important;
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }

  .u-lg-flexWrapReverse {
    -webkit-flex-wrap: wrap-reverse !important;
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }

  /**
   * Align items along the main axis of the current line of the flex container
   */

  .u-lg-flexJustifyStart {
    -webkit-justify-content: flex-start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  .u-lg-flexJustifyEnd {
    -webkit-justify-content: flex-end !important;
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }

  .u-lg-flexJustifyCenter {
    -webkit-justify-content: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }

  .u-lg-flexJustifyBetween {
    -webkit-justify-content: space-between !important;
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }

  .u-lg-flexJustifyAround {
    -webkit-justify-content: space-around !important;
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }

  /**
   * Align items in the cross axis of the current line of the flex container
   * Similar to `justify-content` but in the perpendicular direction
   */

  .u-lg-flexAlignItemsStart {
    -webkit-align-items: flex-start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }

  .u-lg-flexAlignItemsEnd {
    -webkit-align-items: flex-end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }

  .u-lg-flexAlignItemsCenter {
    -webkit-align-items: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .u-lg-flexAlignItemsStretch {
    -webkit-align-items: stretch !important;
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }

  .u-lg-flexAlignItemsBaseline {
    -webkit-align-items: baseline !important;
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }

  /**
   * Aligns items within the flex container when there is extra space in the cross-axis
   *
   * Has no effect when there is only one line of flex items.
   */

  .u-lg-flexAlignContentStart {
    -webkit-align-content: flex-start !important;
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }

  .u-lg-flexAlignContentEnd {
    -webkit-align-content: flex-end !important;
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }

  .u-lg-flexAlignContentCenter {
    -webkit-align-content: center !important;
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }

  .u-lg-flexAlignContentStretch {
    -webkit-align-content: stretch !important;
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }

  .u-lg-flexAlignContentBetween {
    -webkit-align-content: space-between !important;
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }

  .u-lg-flexAlignContentAround {
    -webkit-align-content: space-around !important;
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }

  /**
   * 1. Set the flex-shrink default explicitly to fix IE10 - http://git.io/vllC7
   */

  /* postcss-bem-linter: ignore */

  .u-lg-flex > *,
  .u-lg-flexInline > * {
    -webkit-flex-shrink: 1;
    -ms-flex-negative: 1;
    flex-shrink: 1;
    /* 1 */
  }

  /* Applies to flex items
     ========================================================================== */

  /**
   * Override default alignment of single item when specified by `align-items`
   */

  .u-lg-flexAlignSelfStart {
    -webkit-align-self: flex-start !important;
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }

  .u-lg-flexAlignSelfEnd {
    -webkit-align-self: flex-end !important;
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }

  .u-lg-flexAlignSelfCenter {
    -webkit-align-self: center !important;
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }

  .u-lg-flexAlignSelfStretch {
    -webkit-align-self: stretch !important;
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }

  .u-lg-flexAlignSelfBaseline {
    -webkit-align-self: baseline !important;
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }

  .u-lg-flexAlignSelfAuto {
    -webkit-align-self: auto !important;
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }

  /**
   * Change order without editing underlying HTML
   */

  .u-lg-flexOrderFirst {
    -webkit-order: -1 !important;
    -ms-flex-order: -1 !important;
    order: -1 !important;
  }

  .u-lg-flexOrderLast {
    -webkit-order: 1 !important;
    -ms-flex-order: 1 !important;
    order: 1 !important;
  }

  .u-lg-flexOrderNone {
    -webkit-order: 0 !important;
    -ms-flex-order: 0 !important;
    order: 0 !important;
  }

  /**
   * Specify the flex grow factor, which determines how much the flex item will
   * grow relative to the rest of the flex items in the flex container.
   *
   * Supports 1-5 proportions
   *
   * 1. Provide all values to avoid IE10 bug with shorthand flex - http://git.io/vllC7
   *    Use `0%` to avoid bug in IE10/11 with unitless flex basis - http://git.io/vllWx
   */

  .u-lg-flexGrow1 {
    -webkit-flex: 1 1 0% !important;
    -ms-flex: 1 1 0% !important;
    flex: 1 1 0% !important;
    /* 1 */
  }

  .u-lg-flexGrow2 {
    -webkit-flex: 2 1 0% !important;
    -ms-flex: 2 1 0% !important;
    flex: 2 1 0% !important;
  }

  .u-lg-flexGrow3 {
    -webkit-flex: 3 1 0% !important;
    -ms-flex: 3 1 0% !important;
    flex: 3 1 0% !important;
  }

  .u-lg-flexGrow4 {
    -webkit-flex: 4 1 0% !important;
    -ms-flex: 4 1 0% !important;
    flex: 4 1 0% !important;
  }

  .u-lg-flexGrow5 {
    -webkit-flex: 5 1 0% !important;
    -ms-flex: 5 1 0% !important;
    flex: 5 1 0% !important;
  }
}

/** @define Arrange */



/**
 * This component lets you lay out a row of cells in various ways. You can
 * specify whether a cell should be wide enough to fit its content, or take up
 * the remaining space in the row. It's also possible to give all cells an
 * equal width, and to control their vertical alignment.
 */

/**
 * 1. Protect against the component expanding beyond the confines of its
 *    container if properties affecting the box-model are applied to the
 *    component. Mainly necessary because of (5).
 * 2. Rely on table layout.
 * 3. Zero out the default spacing that might be on an element (e.g., `ul`).
 * 4. Make sure the component fills at least the full width of its parent.
 * 5. Reset the table-layout algorithm in case a component is nested.
 */

.Arrange {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 2 */
  margin: 0;
  /* 3 */
  min-width: 100%;
  /* 4 */
  padding: 0;
  /* 3 */
  table-layout: auto;
  /* 5 */
}

/**
 * There are two possible types of child. `sizeFill` will expand to fill all
 * of the remaining space not filled by `sizeFit` elements.
 *
 * 1. Zero out any default spacing that might be on an element (e.g., `li`);
 *    Margin has no effect when coupled with `display: table-cell`.
 * 2. All cells are top-aligned by default
 */

.Arrange-sizeFill,
.Arrange-sizeFit {
  display: table-cell;
  padding: 0;
  /* 1 */
  vertical-align: top;
  /* 2 */
}

/**
 * Make sure the main content block expands to fill the remaining space.
 */

.Arrange-sizeFill {
  width: 100%;
}

/**
 * Where possible, protect against large images breaking the layout. Prevent them from
 * exceeding the width of the main content block by making them fluid.
 *
 * Only work for all browsers with the `Arrange--equally` variant. For Firefox
 * and IE to constrain image dimensions for other layouts, large images will
 * need their width set to `100%`.
 */

.Arrange-sizeFill img {
  height: auto;
  max-width: 100%;
}

/**
 * Defend against a side-effect of this layout pattern: images in
 * 'Arrange-sizeFit' cannot be fluid, otherwise they lose their ability to
 * provide size to a cell.
 */

.Arrange-sizeFit img {
  max-width: none !important;
  width: auto !important;
}

/* Vertical alignment modifiers
   ========================================================================== */

.Arrange--middle > .Arrange-sizeFill,
.Arrange--middle > .Arrange-sizeFit {
  vertical-align: middle;
}

.Arrange--bottom > .Arrange-sizeFill,
.Arrange--bottom > .Arrange-sizeFit {
  vertical-align: bottom;
}

/* Equal-width modifier
   ========================================================================== */

/**
 * This layout algorithm will create equal-width table cells, irrespective of
 * the width of their content.
 *
 * 1. The layout algorithm requires a set width to correctly calculate table
 *    cell width.
 */

.Arrange--equal {
  table-layout: fixed;
  width: 100%;
  /* 1 */
}

/**
 * Give the cells an equal width. This value ensures that Arrange is still 100%
 * wide when gutters are used in conjunctions with equal-width cells.
 *
 * It's recommended that only 'Arrange-sizeFill' be used for equal width cells.
 * Their inner images will automatically be responsive.
 */

.Arrange--equal > .Arrange-sizeFill,
.Arrange--equal > .Arrange-sizeFit {
  width: 1%;
}

/* Gutter modifier
   ========================================================================== */

/**
 * Add a gutter between cells
 *
 * NOTE: this can trigger a horizontal scrollbar if the component is as wide as
 * the viewport. Use padding on a container, or `overflow-x:hidden` to protect
 * against it.
 */

.Arrange--withGutter {
  margin: 0 -5px;
}

.Arrange--withGutter > .Arrange-sizeFit,
.Arrange--withGutter > .Arrange-sizeFill {
  padding: 0 5px;
}

/** @define Button; use strict */



/**
 * The button classes are best applied to links, buttons, and submit inputs.
 * These components can be used in forms, as calls to action, or as part of the
 * general UI of the site/app.
 */

/**
 * 1. Corrects inability to style clickable `input` types in iOS.
 * 2. Normalize `box-sizing` across all elements that this component could be
 *    applied to.
 * 3. Inherit text color from ancestor.
 * 4. Inherit font styles from ancestor.
 * 5. Normalize `line-height`. For `input`, it can't be changed from `normal` in Firefox 4+.
 * 6. Prevent button text from being selectable.
 * 7. Make sure `input` will wrap text across multiple lines.
 */

.Button {
  -webkit-appearance: none;
  /* 1 */
  background: transparent;
  border-color: currentcolor;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  /* 2 */
  color: inherit;
  /* 3 */
  cursor: pointer;
  display: inline-block;
  font: inherit;
  /* 4 */
  line-height: normal;
  /* 5 */
  margin: 0;
  padding: 0.4em 0.75em;
  position: relative;
  text-align: center;
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /* 6 */
  white-space: normal;
  /* 7 */
}

/**
 * Remove excess padding and border in Firefox 4+
 */

.Button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * Work around a Firefox/IE bug where the transparent `button` background
 * results in a loss of the default `button` focus styles.
 */

.Button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

/**
 * UI states
 */

.Button:hover,
.Button:focus,
.Button:active {
  text-decoration: none;
}

.Button:disabled,
.Button.is-disabled {
  cursor: default;
  opacity: 0.6;
}

/** @define FlexEmbed; use strict */

/**
 * Flexible media embeds
 *
 * For use with media embeds – such as videos, slideshows, or even images –
 * that need to retain a specific aspect ratio but adapt to the width of their
 * containing element.
 *
 * Based on: http://alistapart.com/article/creating-intrinsic-ratios-for-video
 */

.FlexEmbed {
  display: block;
  overflow: hidden;
  position: relative;
}

/**
 * The aspect-ratio hack is applied to an empty element because it allows
 * the component to respect `max-height`. Default aspect ratio is 1:1.
 */

.FlexEmbed-ratio {
  display: block;
  padding-bottom: 100%;
  width: 100%;
}

/**
 * Modifier: 3:1 aspect ratio
 */

.FlexEmbed-ratio--3by1 {
  padding-bottom: 33.33333333333333%;
}

/**
 * Modifier: 2:1 aspect ratio
 */

.FlexEmbed-ratio--2by1 {
  padding-bottom: 50%;
}

/**
 * Modifier: 16:9 aspect ratio
 */

.FlexEmbed-ratio--16by9 {
  padding-bottom: 56.25%;
}

/**
 * Modifier: 4:3 aspect ratio
 */

.FlexEmbed-ratio--4by3 {
  padding-bottom: 75%;
}

/**
 * Fit the content to the aspect ratio
 */

.FlexEmbed-content {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

/** @define Grid */

:root {
  // --Grid-gutter-size: 20px;
}

/**
 * Core grid component
 *
 * DO NOT apply dimension or offset utilities to the `Grid` element. All cell
 * widths and offsets should be applied to child grid cells.
 */

/* Grid container
   ========================================================================== */

/**
 * All content must be contained within child `Grid-cell` elements.
 *
 * 1. Account for browser defaults of elements that might be the root node of
 *    the component.
 */

.Grid {
  box-sizing: border-box;
  display: flex; /* 1 */
  flex-flow: row wrap;
  margin: 0; /* 1 */
  padding: 0; /* 1 */
}

/**
 * Modifier: center align all grid cells
 */

.Grid--alignCenter {
  justify-content: center;
}

/**
 * Modifier: right align all grid cells
 */

.Grid--alignRight {
  justify-content: flex-end;
}

/**
 * Modifier: middle-align grid cells
 */

.Grid--alignMiddle {
  align-items: center;
}

/**
 * Modifier: bottom-align grid cells
 */

.Grid--alignBottom {
  align-items: flex-end;
}

/**
 * Modifier: allow cells to equal distribute width
 *
 * 1. Provide all values to avoid IE10 bug with shorthand flex
 *    http://git.io/vllC7
 *
 *    Use `0%` to avoid bug in IE10/11 with unitless flex basis
 *    http://git.io/vllWx
 */

.Grid--fit > .Grid-cell {
  flex: 1 1 0%; /* 1 */
}

/**
 * Modifier: all cells match height of tallest cell in a row
 */

.Grid--equalHeight > .Grid-cell {
  display: flex;
}

/**
 * Modifier: gutters
 */

.Grid--withGutter {
  // margin: 0 calc(-0.5 * var(--Grid-gutter-size));
  margin: -10px;
}

.Grid--withGutter > .Grid-cell {
  // padding: 0 calc(0.5 * var(--Grid-gutter-size));
  padding: 0 10px;
}

/* Grid cell
   ========================================================================== */

/**
 * No explicit width by default. Rely on combining `Grid-cell` with a dimension
 * utility or a component class that extends 'Grid'.
 *
 * 1. Set flex items to full width by default
 */

.Grid-cell {
  box-sizing: inherit;
  flex: 0 0 100%; /* 1 */
}

/**
 * Modifier: horizontally center one unit
 * Set a specific unit to be horizontally centered. Doesn't affect
 * any other units. Can still contain a child `Grid` object.
 */

.Grid-cell--center {
  margin: 0 auto;
}
