

// --------------------------------------------------------------------------
//   IMAGE COMPONENT
//   2 x special cases
// --------------------------------------------------------------------------


@mixin Img {
	display: block;

	@include respond-to(sm) {
		float: right;
	}
}

.Img--ray {
	@include Img();
	height: auto;
	margin-bottom: space(1/3);
	width: 80px;

	@include respond-to(sm) {
		height: 22px;
		width: 119px;
	}
}

.Img--elite {
	@include Img();
	height: auto;
	width: 80px;

	@include respond-to(sm) {
		width: 119px;
	}
}
