

// --------------------------------------------------------------------------
// COMPONENT: CONTAINER
// --------------------------------------------------------------------------


.Container {
	@include clearfix();
	margin: 0 auto;
	max-width: size(container, default);
	padding: 0 space(0.75);
	position: relative;

	@include respond-to(lg) {
		padding: 0;
	}

	&--textWidth {
		margin: 0 space(1);
		max-width: size(container, text-column);
		padding: 0;

		@include respond-to(xs) {
			margin: 0 auto !important;

		}
	}

	&--heroImage {
		padding: 0 !important;
	}

	&--textColumn {
		> h1,
		> h2,
		> h3,
		> p,
		> ul,
		> blockquote,
		> iframe,
		> figure,
		> figcaption,
		> .HzRule {
			margin-left: auto;
			margin-right: auto;
			max-width: size(container, text-column);
		}
	}

	&--half {
		margin: 0;
		max-width: size(container, half);
		//
		// @include respond-to(xs) {
		//     margin: 0 auto !important;
		// }
	}

	&--lg {
		margin: 0;
		max-width: size(container, wide-picture);

		@include respond-to(xs) {
			margin: 0 auto !important;
		}
	}

	&--full {
		margin: 0;
		max-width: size(container, wide-picture);
		padding: 0;

		@include respond-to(xs) {
			margin: 0 auto !important;
			padding: 0 space(0.75);
		}
	}
}
