

// --------------------------------------------------------------------------
//   LAYOUT: SECTION
// --------------------------------------------------------------------------


.Section,
.Footer {
    margin: space(2.5) 0 0;

    @include respond-to(sm) {
        margin: space(5) 0 0;
    }
}
